define("ember-svg-jar/inlined/icon-nav-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.573 16.76a9.575 9.575 0 01-3.32-3.318C.417 12.056 0 10.577 0 9s.417-3.058 1.253-4.443a9.583 9.583 0 013.32-3.318A8.518 8.518 0 019.029 0c1.709 0 3.242.413 4.602 1.24a9.032 9.032 0 013.204 3.288A8.908 8.908 0 0118 9a9.053 9.053 0 01-1.165 4.5 8.666 8.666 0 01-3.233 3.288C12.224 17.596 10.7 18 9.03 18a8.513 8.513 0 01-4.456-1.24z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});