define("ember-svg-jar/inlined/icon-logo-shopware", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M46.947 49.674c3.908 1.516 7.598 2.947 10.104 4.833a.4.4 0 01.165.337.42.42 0 01-.168.33 28.626 28.626 0 01-17.147 5.65c-7.705 0-14.948-2.999-20.392-8.443-5.447-5.444-8.447-12.687-8.447-20.388 0-7.701 3-14.944 8.447-20.388 5.448-5.448 12.69-8.447 20.392-8.447a28.758 28.758 0 0119.261 7.382.411.411 0 01-.33.716 48.654 48.654 0 00-6.593-.426c-8.4 0-15.22 1.876-19.72 5.419-4.016 3.16-6.138 7.532-6.138 12.646 0 5.7 2.184 10.205 6.67 13.777 3.956 3.145 9.222 5.189 13.872 6.993l.019.007.005.002zm21.52-20.636a.42.42 0 01.148.283c.08.917.12 1.817.12 2.665 0 3.62-.664 7.154-1.974 10.51a.415.415 0 01-.588.212c-3.422-1.901-7.148-3.291-10.754-4.636l-.02-.007c-7.008-2.614-13.06-4.872-13.06-9.824 0-1.765.74-3.274 2.14-4.353 1.615-1.248 4.034-1.88 7.19-1.88 5.915 0 10.782 2.038 16.799 7.03z\" fill=\"#189EFF\"/>",
    "attrs": {
      "width": "80",
      "height": "64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});