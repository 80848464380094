define("ember-link/link", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UILink = void 0;
  _exports.isQueryParams = isQueryParams;
  var _dec, _dec2, _dec3, _class, _descriptor, _dec4, _dec5, _dec6, _class3, _descriptor2; // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const MAIN_BUTTON = 0;
  function isQueryParams(maybeQueryParam) {
    return maybeQueryParam?.isQueryParams && typeof maybeQueryParam.values === 'object';
  }

  // eslint-disable-next-line @typescript-eslint/ban-types

  function freezeParams(params) {
    if (false /* DEBUG */) {
      if (params.models) Object.freeze(params.models);
      if (params.query) Object.freeze(params.query);
      return Object.freeze(params);
    }
    return params;
  }
  function isUnmodifiedLeftClick(event) {
    return event.button === MAIN_BUTTON && !event.ctrlKey && !event.metaKey;
  }
  function isMouseEvent(event) {
    return typeof event === 'object' && event !== null && 'button' in event;
  }
  let Link = _exports.default = (_dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = Ember._action, (_class3 = class Link {
    // eslint-disable-next-line @typescript-eslint/naming-convention

    constructor(linkManager, params) {
      (0, _initializerDefineProperty2.default)(this, "_params", _descriptor2, this);
      (0, _defineProperty2.default)(this, "_linkManager", void 0);
      Ember.setOwner(this, Ember.getOwner(linkManager));
      this._linkManager = linkManager;
      this._params = freezeParams(params);
    }
    get _routeArgs() {
      const {
        routeName,
        models,
        queryParams
      } = this;
      if (queryParams) {
        return [routeName, ...models,
        // Cloning `queryParams` is necessary, since we freeze it, but Ember
        // wants to mutate it.
        {
          queryParams: {
            ...queryParams
          }
        }];
      }
      return [routeName, ...models];
    }

    /**
     * Whether this route is currently active, including potentially supplied
     * models and query params.
     */
    get isActive() {
      if (!this._linkManager.isRouterInitialized) return false;
      this._linkManager.currentTransitionStack; // eslint-disable-line @typescript-eslint/no-unused-expressions
      return this._linkManager.router.isActive(...this._routeArgs);
    }

    /**
     * Whether this route is currently active, including potentially supplied
     * models, but ignoring query params.
     */
    get isActiveWithoutQueryParams() {
      if (!this._linkManager.isRouterInitialized) return false;
      this._linkManager.currentTransitionStack; // eslint-disable-line @typescript-eslint/no-unused-expressions
      return this._linkManager.router.isActive(this.routeName,
      // Unfortunately TypeScript is not clever enough to support "rest"
      // parameters in the middle.
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ...this.models);
    }

    /**
     * Whether this route is currently active, but ignoring models and query
     * params.
     */
    get isActiveWithoutModels() {
      if (!this._linkManager.isRouterInitialized) return false;
      this._linkManager.currentTransitionStack; // eslint-disable-line @typescript-eslint/no-unused-expressions
      return this._linkManager.router.isActive(this.routeName);
    }

    /**
     * Whether this route is currently being transitioned into / entered.
     */
    get isEntering() {
      return this._isTransitioning('to');
    }

    /**
     * Whether this route is currently being transitioned out of / exited.
     */
    get isExiting() {
      return this._isTransitioning('from');
    }

    /**
     * The URL for this link that you can pass to an `<a>` tag as the `href`
     * attribute.
     */
    get url() {
      if (!this._linkManager.isRouterInitialized) return '';
      return this._linkManager.router.urlFor(...this._routeArgs);
    }

    /**
     * Deprecated alias for `url`.
     */
    get href() {
      (false && !(false) && Ember.deprecate('`href` is deprecated. Use `url` instead.', false, {
        id: 'ember-link.link.href',
        until: '2.0.0',
        for: 'ember-link',
        since: {
          available: '1.1.0',
          enabled: '1.1.0'
        }
      }));
      return this.url;
    }

    /**
     * Alias for `url`.
     *
     * Allows for more ergonomic composition as query parameters.
     *
     * ```hbs
     * {{link "foo" query=(hash bar=(link "bar"))}}
     * ```
     */
    toString() {
      return this.url;
    }

    /**
     * The `RouteInfo` object for the target route.
     */
    // get route(): RouteInfo {
    //   return this._linkManager.router.recognize(this.url);
    // }

    /**
     * The target route name of this link.
     */
    get routeName() {
      return this._params.route;
    }

    /**
     * The fully qualified target route name of this link.
     */
    get qualifiedRouteName() {
      // Ignore `Property 'recognize' does not exist on type 'RouterService'.`
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const routeInfo = this._linkManager.router.recognize(this.url);
      return routeInfo.name;
    }

    /**
     * The route models passed in this link.
     */
    get models() {
      return this._params.models ?? [];
    }

    /**
     * The query params for this link, if specified.
     */
    get queryParams() {
      return this._params.query;
    }
    _isTransitioning(direction) {
      return this._linkManager.currentTransitionStack?.some(transition => {
        return transition[direction]?.name === this.qualifiedRouteName;
      }) ?? false;
    }

    /**
     * Transition into the target route.
     */
    transitionTo() {
      (false && !(this._linkManager.isRouterInitialized) && Ember.assert('You can only call `transitionTo`, when the router is initialized, e.g. when using `setupApplicationTest`.', this._linkManager.isRouterInitialized));
      this._params.onTransitionTo?.();
      return this._linkManager.router.transitionTo(...this._routeArgs);
    }

    /**
     * Transition into the target route while replacing the current URL, if
     * possible.
     */
    replaceWith() {
      (false && !(this._linkManager.isRouterInitialized) && Ember.assert('You can only call `replaceWith`, when the router is initialized, e.g. when using `setupApplicationTest`.', this._linkManager.isRouterInitialized));
      this._params.onReplaceWith?.();
      return this._linkManager.router.replaceWith(...this._routeArgs);
    }
  }, (_descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class3.prototype, "_params", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class3.prototype, "transitionTo", [_dec5], Object.getOwnPropertyDescriptor(_class3.prototype, "transitionTo"), _class3.prototype), (0, _applyDecoratedDescriptor2.default)(_class3.prototype, "replaceWith", [_dec6], Object.getOwnPropertyDescriptor(_class3.prototype, "replaceWith"), _class3.prototype)), _class3));
  let UILink = _exports.UILink = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class UILink extends Link {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "_params", _descriptor, this);
    }
    preventDefault(event) {
      if ((this._params.preventDefault ?? true) && typeof event?.preventDefault === 'function') {
        event.preventDefault();
      }
    }

    /**
     * Transition into the target route.
     *
     * Optionally call `preventDefault()`, if an `Event` is passed in.
     */
    transitionTo(event) {
      if (isMouseEvent(event) && !isUnmodifiedLeftClick(event)) return;

      // Intentionally putting this *before* the assertion to prevent navigating
      // away in case of a failed assertion.
      this.preventDefault(event);
      return super.transitionTo();
    }

    /**
     * Transition into the target route while replacing the current URL, if
     * possible.
     *
     * Optionally call `preventDefault()`, if an `Event` is passed in.
     */
    replaceWith(event) {
      if (isMouseEvent(event) && !isUnmodifiedLeftClick(event)) return;

      // Intentionally putting this *before* the assertion to prevent navigating
      // away in case of a failed assertion.
      this.preventDefault(event);
      return super.replaceWith();
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_params", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "transitionTo", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "transitionTo"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "replaceWith", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "replaceWith"), _class.prototype)), _class));
});