define("ember-svg-jar/inlined/icon-logo-alerts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-rule=\"evenodd\" fill-rule=\"evenodd\"><path d=\"M68.33 25l.25-.32.42.32v34a2.006 2.006 0 01-2 2H13a2.006 2.006 0 01-2-2V25l.42-.32.25.32z\" fill=\"#02193c\" fill-opacity=\".5\"/><path d=\"M68.58 24.68l-.25.32L41 46h-2L11.67 25l-.25-.32L21 17.23 34.18 7l4.61-3.58c.71-.56 1.71-.56 2.42 0L45.82 7 59 17.23z\" fill=\"#02193c\" fill-opacity=\".5\"/><path d=\"M59 32.17L41 46h-2L21 32.17V9a2.006 2.006 0 012-2h34a2.006 2.006 0 012 2z\" fill=\"#ffd87c\"/><path d=\"M68.61 60.18A1.979 1.979 0 0167 61H13a1.979 1.979 0 01-1.61-.82L34.3 42.39l4.49-3.49c.71-.56 1.711-.56 2.42 0l4.49 3.49zM40.026 10C33.384 10 28 15.384 28 22.026c0 6.641 5.384 12.025 12.026 12.025a1.002 1.002 0 100-2.004c-5.535 0-10.022-4.487-10.022-10.021 0-5.535 4.487-10.022 10.022-10.022 5.534 0 10.021 4.487 10.021 10.022v4.008a2.004 2.004 0 11-4.008 0v-4.008a1.002 1.002 0 00-2.005 0 4.008 4.008 0 11-4.008-4.009 1.002 1.002 0 100-2.004 6.013 6.013 0 104.051 10.443 3.997 3.997 0 007.974-.422v-4.008C52.044 15.387 46.664 10.007 40.026 10\" fill=\"#02193c\" fill-opacity=\".5\"/></g>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 80 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});