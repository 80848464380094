define("ember-svg-jar/inlined/icon-books-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 32C0 14.3 14.3 0 32 0h64c17.7 0 32 14.3 32 32v64H0V32zm0 96h128v256H0V128zm0 288h128v64c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32v-64zM160 32c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v64H160V32zm0 96h128v256H160V128zm0 288h128v64c0 17.7-14.3 32-32 32h-64c-17.7 0-32-14.3-32-32v-64zm203.6-19.9L320 232.6v-89.8l100.4-26.9 66 247.4-122.8 32.8zM412.2 85L320 109.6V11l36.9-9.9c16.9-4.6 34.4 5.5 38.9 22.6L412.2 85zm-40.4 342l122.8-32.9 16.3 61.1c4.5 17-5.5 34.5-22.5 39.1L427 510.8c-16.9 4.6-34.4-5.5-38.9-22.6L371.8 427z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 512 512"
    }
  };
});