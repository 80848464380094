define("ember-svg-jar/inlined/icon-logo-zenloop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#7EC03B\" d=\"M14.641 4.157c3.31 1.14 5.53 4.223 5.53 7.701 0 .456-.045.912-.122 1.367.843-1.033 1.149-2.096 1.256-3.174.23-2.233-.245-4.557-1.149-6.806-4.78-4.496-12.347-4.298-16.881.456-.414.44-.797.912-1.149 1.398.2-.167.414-.32.613-.471 3.462-2.43 8.21-2.902 11.902-.471z\"/><path fill=\"#5CA1DF\" d=\"M21.795 5.144a11.342 11.342 0 00-1.639-1.883c.904 2.248 1.379 4.572 1.15 6.805-.108 1.078-.414 2.141-1.257 3.174-.03.167-.061.335-.107.502a8.89 8.89 0 01-2.42 4.131c-.246.243.23 1.2.919 1.884.582.592 1.24 1.018 1.148 1.2 4.734-3.92 5.699-10.77 2.206-15.813z\"/><path fill=\"#D69EE1\" d=\"M19.482 21.033c-2.221.592-5.453-1.261-5.744-1.2-4.443.987-8.84-1.793-9.82-6.182a7.712 7.712 0 01-.199-1.778v-.228c-1.363 4.451.414 8.02 1.348 9.904 4.397 3.069 10.31 2.856 14.461-.531-.015 0-.03 0-.046.015z\"/><path fill=\"#FFC12C\" d=\"M2.754 4.643a8.487 8.487 0 00-.444.334l-.17.123-.015.015c-3.738 5.347-2.42 12.683 2.957 16.435-.935-1.883-2.712-5.468-1.348-9.904.015-.303.03-.607.076-.896.628-4.466 4.795-7.564 9.283-6.942a9.15 9.15 0 011.547.365c-3.691-2.446-8.44-1.975-11.887.47z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});