define("ember-svg-jar/inlined/icon-gears-sharp-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M125 8h70l10 48.1c13.8 5.2 26.5 12.7 37.5 22L285.6 64l34.4 59.4-33.9 30.3c1.3 7.3 1.9 14.7 1.9 22.3s-.7 15.1-1.9 22.3l33.9 30.3-34.4 59.4-43.1-14.2c-11.1 9.3-23.7 16.8-37.5 22L195 344h-70l-10-48.1c-13.8-5.2-26.5-12.7-37.5-22L34.4 288 0 228.6l33.9-30.3c-1.2-7.2-1.9-14.7-1.9-22.3s.7-15.1 1.9-22.3L0 123.4 34.4 64l43.1 14.2c11.1-9.3 23.7-16.8 37.5-22L125 8zm83 168a48 48 0 10-96 0 48 48 0 1096 0zm424 210.4l-47.8 9.8c-4.9 13.4-12 25.8-20.9 36.7l15 44.8-60.6 34.3-30.9-34c-7.4 1.3-15 2-22.7 2s-15.4-.7-22.7-2l-30.9 34-60.6-34.4 15-44.8c-8.9-10.9-16-23.3-20.9-36.7l-48-9.7v-68.8l47.8-9.8c4.9-13.4 12-25.8 20.9-36.7l-15-44.8 60.6-34.3 30.9 34c7.4-1.3 15-2 22.7-2s15.4.7 22.7 2l30.9-34 60.6 34.4-15 44.8c8.9 10.9 16 23.3 20.9 36.7l47.8 9.8v68.7zM464 400a48 48 0 100-96 48 48 0 100 96z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 640 512"
    }
  };
});