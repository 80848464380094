define("ember-svg-jar/inlined/icon-logo-zendesk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.735 22.896c8.142 0 14.739-6.672 14.739-14.896H8c0 8.224 6.597 14.896 14.735 14.896zm34.53 18.206c-8.142 0-14.739 6.67-14.739 14.895H72c0-8.225-6.597-14.895-14.735-14.895zM37.404 21.52V56H8l29.403-34.48zM41.885 8.1v34.476L71.855 8.1h-29.97z\" fill=\"#00363D\"/>",
    "attrs": {
      "width": "80",
      "height": "64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});