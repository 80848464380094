define("ember-svg-jar/inlined/icon-bells-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M230.8 245.5l18.7 51.2c11.7 31.8 12.9 66.5 3.5 99.1l-1.1 3.9-230.5-83.9C10 311.7 2 301.5.6 289.5s4.1-23.8 14.3-30.4l8.2-5.4C57.6 231 84 197.8 98.2 159l14.7-40C138 50.4 214 15.1 282.6 40.1c4.8 1.7 9.4 3.7 13.9 6-64.1 42.2-93.3 124.2-65.7 199.5zm30.1-10.9c-26.1-71.3 10.5-150.2 81.9-176.2s150.4 10.8 176.5 82.1l18.7 51.2c14 38.2 39.8 71 73.6 93.6l13.7 9.2c10 6.7 15.4 18.5 14 30.4s-9.5 22.1-20.8 26.2L321.1 459.4c-11.3 4.1-24 1.5-32.8-6.7s-12.3-20.7-9-32.2l4.6-15.8c11.2-39 9.7-80.7-4.3-118.9l-18.8-51.2zM480.8 480c-21.2 0-40-10.2-51.7-25.9l116.1-42.3c.1 1.3.1 2.6.1 3.9 0 35.5-28.9 64.3-64.5 64.3zM94.3 376.1l117.6 43.4c-11.6 17.1-31.2 28.4-53.5 28.4-35.6 0-64.5-28.8-64.5-64.3 0-2.5.1-5.1.4-7.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 640 512"
    }
  };
});