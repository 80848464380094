define("ember-svg-jar/inlined/icon-logo-inxmail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 61h38.056L72 32.5 53.056 4H15l11.903 17.938 6.035-9.053h14.083L33.944 32.332 47.021 51.78H32.938l-6.035-9.053L15 61z\" fill=\"#0E1116\"/>",
    "attrs": {
      "width": "80",
      "height": "64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});