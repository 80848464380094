define("ember-svg-jar/inlined/icon-user-profile-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M5.941 20c0-2.109 1.664-4.734 6.46-4.734 4.793 0 6.457 2.606 6.457 4.716\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M16.526 8.126a4.126 4.126 0 11-8.251 0 4.126 4.126 0 018.25 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "5.19 3.34 14.42 17.41"
    }
  };
});