define("ember-useragent/helpers/user-agent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.extend({
    userAgent: Ember.inject.service(),
    compute(_ref) {
      let [prop] = _ref;
      return Ember.get(this, `userAgent.${prop}`);
    }
  });
});