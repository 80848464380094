define("ember-svg-jar/inlined/icon-stars-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<!--! Font Awesome Pro 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.--><path d=\"M356 60L336 0l-20 60-60 20 60 20 20 60 20-60 60-20-60-20zm108 148l-16-48-16 48-48 16 48 16 16 48 16-48 48-16-48-16zm-210.7 57.1L193.5 144l-59.8 121.1L0 284.6l96.7 94.3L73.9 512l119.6-62.9L313 512l-22.8-133.1 96.7-94.3-133.6-19.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 512 512"
    }
  };
});