define("ember-intl/macros/intl", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.__intlInjectionName = void 0;
  _exports.default = intl;
  /**
   * @private
   * @hide
   */
  const __intlInjectionName = _exports.__intlInjectionName = `intl-${Date.now().toString(36)}`;
  function intl() {
    for (var _len = arguments.length, dependentKeysAndGetterFn = new Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeysAndGetterFn[_key] = arguments[_key];
    }
    const getterFn = dependentKeysAndGetterFn.pop();
    const dependentKeys = dependentKeysAndGetterFn;
    return Ember.computed(`${__intlInjectionName}.locale`, ...dependentKeys, function (propertyKey) {
      if (!Ember.get(this, __intlInjectionName)) {
        Ember.defineProperty(this, __intlInjectionName, {
          value: Ember.getOwner(this).lookup('service:intl'),
          enumerable: false
        });
      }
      const intl = Ember.get(this, __intlInjectionName);
      return getterFn.call(this, intl, propertyKey, this);
    });
  }
});