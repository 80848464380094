define("ember-svg-jar/inlined/icon-logo-webhooks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M38.16 27.29c-2.567 4.353-5.027 8.568-7.537 12.751-.645 1.075-.964 1.95-.45 3.315 1.423 3.771-.583 7.441-4.354 8.438-3.556.94-7.02-1.418-7.726-5.258-.626-3.399 1.99-6.73 5.705-7.262.311-.045.63-.05 1.152-.09l5.652-9.558c-3.555-3.566-5.67-7.733-5.202-12.897.33-3.65 1.754-6.804 4.355-9.39 4.982-4.951 12.583-5.753 18.449-1.952 5.634 3.65 8.214 10.76 6.014 16.846l-5.164-1.413c.69-3.383.18-6.422-2.083-9.025-1.494-1.718-3.413-2.62-5.594-2.951-4.373-.666-8.666 2.167-9.94 6.496-1.446 4.913.743 8.926 6.723 11.95z\" fill=\"#C73A63\"/><path d=\"M45.5 22.15l5.473 9.732c9.214-2.874 16.16 2.268 18.652 7.773 3.01 6.65.953 14.526-4.959 18.628-6.068 4.212-13.742 3.492-19.118-1.918l4.218-3.558c5.31 3.467 9.955 3.304 13.403-.802 2.94-3.503 2.876-8.725-.15-12.155-3.491-3.957-8.168-4.077-13.82-.279-2.346-4.194-4.732-8.355-7.002-12.58-.766-1.423-1.611-2.25-3.337-2.55-2.882-.505-4.743-3-4.855-5.795-.11-2.765 1.506-5.264 4.031-6.237 2.502-.965 5.437-.186 7.12 1.958 1.374 1.751 1.811 3.723 1.088 5.883-.202.603-.462 1.186-.744 1.9z\" fill=\"#4B4B4B\"/><path d=\"M49.764 47.988H38.64c-1.067 4.332-3.37 7.83-7.338 10.054-3.086 1.729-6.41 2.315-9.95 1.75-6.516-1.038-11.844-6.832-12.313-13.356-.531-7.39 4.612-13.96 11.467-15.436l1.425 5.105c-6.29 3.17-8.466 7.164-6.706 12.157 1.55 4.395 5.951 6.804 10.73 5.872 4.881-.951 7.342-4.958 7.042-11.388 4.627 0 9.258-.047 13.885.024 1.807.028 3.202-.157 4.563-1.73 2.242-2.59 6.367-2.356 8.78.089 2.467 2.499 2.349 6.52-.261 8.914-2.519 2.31-6.498 2.186-8.854-.303-.484-.513-.866-1.122-1.345-1.752z\" fill=\"#4A4A4A\"/>",
    "attrs": {
      "viewBox": "0 0 80 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});