define("ember-svg-jar/inlined/illustration-device-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<filter id=\"illustration-device-group_svg__a\" height=\"139%\" width=\"126.4%\" x=\"-13.2%\" y=\"-19.5%\"><feOffset dy=\"4\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\" stdDeviation=\"4\"/><feColorMatrix in=\"shadowBlurOuter1\" result=\"shadowMatrixOuter1\" values=\"0 0 0 0 0.0549019608 0 0 0 0 0.0666666667 0 0 0 0 0.0862745098 0 0 0 0.15 0\"/><feMerge><feMergeNode in=\"shadowMatrixOuter1\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter><filter id=\"illustration-device-group_svg__b\" height=\"139.7%\" width=\"185.2%\" x=\"-42.6%\" y=\"-19.8%\"><feOffset dy=\"4\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\" stdDeviation=\"4\"/><feColorMatrix in=\"shadowBlurOuter1\" result=\"shadowMatrixOuter1\" values=\"0 0 0 0 0.0549019608 0 0 0 0 0.0666666667 0 0 0 0 0.0862745098 0 0 0 0.15 0\"/><feMerge><feMergeNode in=\"shadowMatrixOuter1\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter><g fill=\"none\" fill-rule=\"evenodd\"><g filter=\"url(#illustration-device-group_svg__a)\" transform=\"translate(28 4)\"><rect fill=\"#fff\" fill-rule=\"nonzero\" height=\"118\" rx=\"9\" width=\"174\"/><g fill=\"#0e1116\"><path d=\"M170 59a4 4 0 11-8 0 4 4 0 018 0\" fill-opacity=\".2\" fill-rule=\"nonzero\"/><rect fill-opacity=\".1\" height=\"110\" rx=\"1\" width=\"141\" x=\"17\" y=\"4\"/></g></g><g filter=\"url(#illustration-device-group_svg__b)\" transform=\"translate(8 26)\"><path d=\"M7.698 116C3.447 116 0 112.469 0 108.114V7.886C0 3.531 3.447 0 7.698 0h38.313C50.423 0 54 3.664 54 8.184v99.632c0 4.52-3.577 8.184-7.989 8.184z\" fill=\"#fff\" fill-rule=\"nonzero\"/><g fill=\"#0e1116\"><path d=\"M3 100h48V12H3z\" fill-opacity=\".1\"/><path d=\"M31 108a4 4 0 11-8 0 4 4 0 018 0\" fill-opacity=\".2\" fill-rule=\"nonzero\"/></g></g></g>",
    "attrs": {
      "height": "154",
      "width": "210",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});