define("ember-svg-jar/inlined/illustration-preview-screen-background-mobile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" opacity=\".6\"><rect fill=\"#ccc\" height=\"307\" rx=\"1\" width=\"275.699\" x=\"1\" y=\".394\"/><g fill=\"#f5f5f5\"><rect height=\"14.453\" rx=\"1\" width=\"219.079\" x=\"29.521\" y=\"22.073\"/><rect height=\"14.453\" rx=\"1\" width=\"219.079\" x=\"29.521\" y=\"58.204\"/><rect height=\"14.453\" rx=\"1\" width=\"219.079\" x=\"29.521\" y=\"94.336\"/><rect height=\"14.453\" rx=\"1\" width=\"219.079\" x=\"29.521\" y=\"130.467\"/><rect height=\"14.453\" rx=\"1\" width=\"219.079\" x=\"29.521\" y=\"166.599\"/><rect height=\"14.453\" rx=\"1\" width=\"219.079\" x=\"29.521\" y=\"202.73\"/><rect height=\"14.453\" rx=\"1\" width=\"219.079\" x=\"29.521\" y=\"238.862\"/><rect height=\"14.453\" rx=\"1\" width=\"219.079\" x=\"29.521\" y=\"274.993\"/></g><g fill=\"#dedfe0\"><g opacity=\".7\"><rect height=\"7.226\" rx=\"1\" width=\"275.6\" x=\".96\" y=\"416.315\"/><rect height=\"7.226\" rx=\"1\" width=\"275.6\" x=\".96\" y=\"430.767\"/><rect height=\"7.226\" rx=\"1\" width=\"275.6\" x=\".96\" y=\"446.22\"/></g><rect height=\"46.518\" rx=\"1\" width=\"276\" x=\".96\" y=\"356.344\"/><rect height=\"14.453\" rx=\"1\" width=\"167.657\" x=\"56.08\" y=\"331.052\"/></g></g>",
    "attrs": {
      "height": "454",
      "viewBox": "0 0 277 454",
      "width": "277",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});