define("ember-cli-flash/components/flash-message", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-cli-flash/templates/components/flash-message"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _flashMessage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  const {
    next,
    cancel
  } = Ember.run;
  let FlashMessage = _exports.default = (_dec = Ember.computed.readOnly('flash.showProgress'), _dec2 = Ember.computed.not('exiting'), _dec3 = Ember.computed.and('showProgress', 'notExiting'), _dec4 = Ember.computed.readOnly('flash.exiting'), _dec5 = Ember.computed('messageStyle'), _dec6 = Ember.computed('flash.type', 'messageStylePrefix', '_defaultMessageStylePrefix'), _dec7 = Ember.computed('flash.type'), _dec8 = Ember.computed('flash.{showProgress,timeout}'), _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, (_class = class FlashMessage extends Ember.Component {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "tagName", '');
      (0, _defineProperty2.default)(this, "layout", _flashMessage.default);
      (0, _defineProperty2.default)(this, "active", false);
      (0, _defineProperty2.default)(this, "messageStyle", 'bootstrap');
      (0, _initializerDefineProperty2.default)(this, "showProgress", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "notExiting", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "showProgressBar", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "exiting", _descriptor4, this);
    }
    get _defaultMessageStylePrefix() {
      const isFoundation = this.messageStyle === 'foundation';
      return isFoundation ? 'alert-box ' : 'alert alert-';
    }
    get alertType() {
      const flashType = this.flash.type || '';
      const prefix = this.messageStylePrefix || this._defaultMessageStylePrefix;
      return `${prefix}${flashType}`;
    }
    get flashType() {
      return Ember.String.classify(this.flash.type || '');
    }
    get progressDuration() {
      if (!this.flash?.showProgress) {
        return false;
      }
      const duration = this.flash?.timeout || 0;
      return Ember.String.htmlSafe(`transition-duration: ${duration}ms`);
    }
    _mouseEnter() {
      if (Ember.isPresent(this.flash)) {
        this.flash.preventExit();
      }
    }
    _mouseLeave() {
      if (Ember.isPresent(this.flash) && !this.flash.exiting) {
        this.flash.allowExit();
      }
    }
    _destroyFlashMessage() {
      if (this.flash) {
        this.flash.destroyMessage();
      }
    }
    onClick() {
      const destroyOnClick = this.flash?.destroyOnClick ?? true;
      if (destroyOnClick) {
        this._destroyFlashMessage();
      }
    }
    onClose() {
      this._destroyFlashMessage();
    }
    onDidInsert(element) {
      const pendingSet = next(this, () => {
        Ember.set(this, 'active', true);
      });
      Ember.set(this, 'pendingSet', pendingSet);
      Ember.set(this, '_mouseEnterHandler', this._mouseEnter);
      Ember.set(this, '_mouseLeaveHandler', this._mouseLeave);
      element.addEventListener('mouseenter', this._mouseEnterHandler);
      element.addEventListener('mouseleave', this._mouseLeaveHandler);
    }
    onWillDestroy(element) {
      element.removeEventListener('mouseenter', this._mouseEnterHandler);
      element.removeEventListener('mouseleave', this._mouseLeaveHandler);
      cancel(this.pendingSet);
      this._destroyFlashMessage();
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showProgress", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "notExiting", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showProgressBar", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "exiting", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_defaultMessageStylePrefix", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "_defaultMessageStylePrefix"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "alertType", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "alertType"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "flashType", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "flashType"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "progressDuration", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "progressDuration"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onClick", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "onClick"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onClose", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "onClose"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onDidInsert", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "onDidInsert"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "onWillDestroy", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "onWillDestroy"), _class.prototype)), _class));
});