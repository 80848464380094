define("ember-svg-jar/inlined/icon-user-profile-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M12.4 14.516c-4.978 0-7.208 2.754-7.208 5.484a.75.75 0 00.75.75l12.918-.018a.75.75 0 00.749-.75c0-2.721-2.23-5.466-7.208-5.466zm0-1.515a4.881 4.881 0 004.876-4.876A4.88 4.88 0 0012.4 3.25a4.88 4.88 0 00-4.875 4.875 4.881 4.881 0 004.875 4.876z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "5.19 3.25 14.42 17.5"
    }
  };
});