define("ember-svg-jar/inlined/icon-logo-episerver", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#icon-logo-episerver_svg__xvvya1tdua)\"><path d=\"M31.026 35.28v6.86a19.164 19.164 0 0019.142-19.142h-6.861a12.296 12.296 0 01-12.281 12.281z\" fill=\"#3BE081\"/><path d=\"M31.026 54.191a12.189 12.189 0 110-24.377v-6.81A18.997 18.997 0 1030.97 61h.055v-6.809z\" fill=\"#0037FF\"/><path d=\"M31.026 54.191v6.81a19.02 19.02 0 0018.998-18.999h-6.81a12.203 12.203 0 01-12.188 12.189z\" fill=\"#0CF\"/><path d=\"M31.026 16.189v6.809A19.02 19.02 0 0050.024 4h-6.81a12.202 12.202 0 01-12.188 12.189z\" fill=\"#861DFF\"/><path d=\"M50.147 16.189v6.809A19.02 19.02 0 0069.145 4h-6.809a12.203 12.203 0 01-12.189 12.189z\" fill=\"#FC7F10\"/></g><defs><clipPath id=\"icon-logo-episerver_svg__xvvya1tdua\"><path fill=\"#fff\" transform=\"translate(12 4)\" d=\"M0 0h57v57H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "80",
      "height": "64",
      "viewBox": "0 0 80 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});