define("ember-svg-jar/inlined/illustration-2fa-phone-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g filter=\"url(#illustration-2fa-phone-text_svg__filter0_d)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.839 138c-4.882 0-8.839-4.048-8.839-9.042V14.042C10 9.048 13.957 5 18.839 5h43.989C67.893 5 72 9.201 72 14.383v114.234c0 5.182-4.107 9.383-9.172 9.383h-43.99z\" fill=\"#fff\"/></g><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13 121h55V19H13v102z\" fill=\"#02193C\" fill-opacity=\".1\"/><path d=\"M46 128a5 5 0 11-10 0 5 5 0 0110 0\" fill=\"#0E1116\" fill-opacity=\".2\"/><path d=\"M52.132 98.123c-1.466.837-3.29-.222-3.29-1.91V84.861h-6.42c-3.547 0-6.422-2.87-6.422-6.409V46.41c0-3.541 2.875-6.41 6.421-6.41H90.58c3.545 0 6.42 2.87 6.42 6.409v32.044c0 3.54-2.875 6.409-6.421 6.409H75.38L52.132 98.123z\" fill=\"#53CDB5\"/><path d=\"M46.625 52h36.75C84.825 52 86 53.343 86 55s-1.175 3-2.625 3h-36.75C45.175 58 44 56.657 44 55s1.175-3 2.625-3zm.042 13h10.666C58.806 65 60 66.343 60 68s-1.194 3-2.667 3H46.667C45.194 71 44 69.657 44 68s1.194-3 2.667-3z\" fill=\"#fff\"/><defs><filter id=\"illustration-2fa-phone-text_svg__filter0_d\" x=\"0\" y=\"0\" width=\"82\" height=\"153\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"/><feOffset dy=\"5\"/><feGaussianBlur stdDeviation=\"5\"/><feColorMatrix values=\"0 0 0 0 0.054902 0 0 0 0 0.0666667 0 0 0 0 0.0862745 0 0 0 0.2 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "width": "97",
      "height": "153",
      "viewBox": "0 0 97 153",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});