define("ember-svg-jar/inlined/icon-logo-freshdesk", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M39.901 3h21.783A7.25 7.25 0 0169 10.316V32.1C69 48.061 56.06 61 40.099 61h-.165A28.9 28.9 0 0111 32.099C11 16.083 23.918 3.165 39.901 3z\" fill=\"#25C16F\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M39.498 16C32.043 16 26 21.916 26 29.213v8.984c.053 2.404 2.032 4.341 4.488 4.393h3.819V32.262h-5.159v-2.885c.315-5.434 4.908-9.682 10.467-9.682 5.56 0 10.153 4.248 10.468 9.682v2.885h-5.226V42.59h3.45v.164c-.035 2.23-1.874 4.03-4.153 4.066h-4.12c-.335 0-.703.164-.703.492a.73.73 0 00.703.688h4.154c2.952-.018 5.34-2.356 5.359-5.246v-.328c2.032-.448 3.469-2.223 3.45-4.262v-8.787C53.164 21.934 47.135 16 39.498 16z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "80",
      "height": "64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});