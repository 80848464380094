define("ember-svg-jar/inlined/icon-settings-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M20.48 6.994l.583 1.011v.007a1.79 1.79 0 01-.656 2.433c-.272.156-.5.383-.655.656l-.01.016a1.778 1.778 0 00.665 2.426l.017.01a1.778 1.778 0 01.639 2.432l-.617 1.03a1.79 1.79 0 01-2.442.655 1.828 1.828 0 00-.857-.239h-.025a1.778 1.778 0 00-1.766 1.79A1.79 1.79 0 0113.566 21h-1.17a1.79 1.79 0 01-1.792-1.79 1.718 1.718 0 00-.239-.858l-.009-.013a1.773 1.773 0 00-2.425-.641l-.012.006a1.791 1.791 0 01-2.433-.703l-.592-1.02-.004-.005a1.779 1.779 0 01.659-2.428 1.791 1.791 0 000-3.103 1.79 1.79 0 01-.655-2.442l.626-1.03a1.79 1.79 0 012.442-.655c.26.154.554.236.856.24a1.79 1.79 0 001.795-1.767V4.78c0-.982.796-1.779 1.779-1.779h1.173l.059.001c.988.032 1.763.86 1.732 1.848.003.301.086.596.24.856l.005.01a1.777 1.777 0 002.437.623 1.79 1.79 0 012.442.656z\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M15.45 11.999a2.467 2.467 0 10-4.933-.001 2.467 2.467 0 004.934 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "3.9 2.25 18.15 19.5"
    }
  };
});