define("ember-svg-jar/inlined/icon-logo-livefeed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-rule=\"evenodd\" fill-rule=\"evenodd\"><path d=\"M68.383 48.338H9.64a.636.636 0 01-.641-.64V3.64A.637.637 0 019.641 3h58.723c.358 0 .641.283.641.641v44.056a.609.609 0 01-.622.641z\" fill=\"#2f424f\"/><path d=\"M13 44.892h52.954V7H13z\" fill=\"#fffffe\"/><path d=\"M55.963 22.772l-1.112-1.395-11.387 9.124-14.27-8.747-7.956 11.141L13 26.373v4.505l7.503 5.957 1.093-1.395 1.452 1.037 7.107-9.972 13.573 8.313 12.857-10.274h9.52V21H55.963z\" fill=\"#e9ecef\"/><path d=\"M56.5 24a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-13 9a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-13-7a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-10 10a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\" fill=\"#fff\"/><path d=\"M21 11.282h15.647V10H21zm0 3h21V13H21zm17-3h10.03V10H38z\" fill=\"#e9ecef\"/><path d=\"M47.398 47H29.715L28 58.368h21.114z\" fill=\"#2f424f\"/><path d=\"M53.486 60.713H24v-3.79c0-.508.445-.923.99-.923h27.505c.546 0 .99.415.99.924v3.789zm-5.408-9.094L47.38 47H29.697L29 51.619z\" fill=\"#27333f\"/><g fill-opacity=\".75\"><path d=\"M16 14.24h4.24V10H16zm44 8.26a3.5 3.5 0 10-7 0 3.5 3.5 0 007 0zm-2 0a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z\" fill=\"#d69ee1\"/><path d=\"M47 31.5a3.5 3.5 0 10-7 0 3.5 3.5 0 007 0zm-2 0a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z\" fill=\"#02193c\"/><path d=\"M34 24.5a3.5 3.5 0 10-7 0 3.5 3.5 0 007 0zm-2 0a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z\" fill=\"#53cdb5\"/><path d=\"M24 34.5a3.5 3.5 0 10-7 0 3.5 3.5 0 007 0zm-2 0a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0z\" fill=\"#ffcb50\"/></g></g>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 80 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});