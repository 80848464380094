define("ember-composable-helpers/helpers/reverse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.reverse = reverse;
  function reverse(_ref) {
    let [array] = _ref;
    if (!Ember.isArray(array)) {
      return [array];
    }
    return Ember.A(array).slice(0).reverse();
  }
  var _default = _exports.default = Ember.Helper.helper(reverse);
});