define("ember-svg-jar/inlined/icon-external", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.868.72H3.6C1.928.72.88 1.904.88 3.58V8.1c0 1.676 1.043 2.86 2.72 2.86h4.798c1.678 0 2.722-1.184 2.722-2.86v-.99M11.12 3.475V.72m0 0H8.365m2.755 0L6.825 5.015\" stroke=\"currentColor\" stroke-width=\"1.44\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "0.16 0 11.68 11.68"
    }
  };
});