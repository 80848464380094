define("ember-useragent/instance-initializers/user-agent", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function getUserAgent(appInstance) {
    if (typeof FastBoot === 'undefined') {
      const userAgent = Ember.get(window, 'navigator.userAgent');
      (false && !(userAgent) && Ember.assert('No `userAgent` present in window.navigator', userAgent));
      return userAgent;
    } else {
      const fastboot = appInstance.lookup('service:fastboot');
      const headers = Ember.get(fastboot, 'request.headers');
      const userAgent = headers.get('user-agent');
      (false && !(userAgent) && Ember.assert('No `user-agent` present in FastBoot headers.', userAgent));
      return userAgent;
    }
  }
  function initialize(appInstance) {
    const service = appInstance.lookup('service:user-agent');
    Ember.set(service, 'userAgent', getUserAgent(appInstance));
  }
  var _default = _exports.default = {
    initialize
  };
});