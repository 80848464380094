define("ember-svg-jar/inlined/icon-collect-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M18.665 18.974c-2.75 2.75-6.824 3.345-10.156 1.804-.492-.199-.896-.358-1.28-.358-1.068.005-2.398 1.042-3.089.35-.69-.69.346-2.02.346-3.094 0-.384-.154-.78-.352-1.274A9.006 9.006 0 015.937 6.246c3.512-3.51 9.216-3.51 12.728 0a9 9 0 010 12.728z\" clip-rule=\"evenodd\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M16.302 12.883v.07m.283-.055a.285.285 0 11-.57 0 .285.285 0 01.57 0zm-4.289-.015v.07m.283-.055a.285.285 0 11-.57 0 .285.285 0 01.57 0zm-4.289-.015v.07m.283-.055a.285.285 0 11-.57 0 .285.285 0 01.57 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "2.55 2.86 19.5 19.5"
    }
  };
});