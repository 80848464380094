define("ember-svg-jar/inlined/icon-collect-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M16.302 13.932a1.035 1.035 0 010-2.069 1.036 1.036 0 010 2.069zm-4.006 0a1.035 1.035 0 010-2.069 1.036 1.036 0 010 2.069zm-4.007 0a1.035 1.035 0 010-2.069 1.036 1.036 0 010 2.069zm10.731-8.04c-3.704-3.7-9.73-3.7-13.435 0-2.807 2.81-3.572 7.117-1.914 10.696.191.475.316.807.316 1.088 0 .332-.14.745-.278 1.145-.265.778-.566 1.66.08 2.303.645.647 1.528.344 2.307.075.397-.137.81-.278 1.135-.279.296 0 .683.156 1.07.311a9.464 9.464 0 003.98.874 9.534 9.534 0 006.74-2.777c3.701-3.704 3.701-9.73 0-13.436z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "2.8 3.12 18.99 18.99"
    }
  };
});