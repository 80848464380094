define("ember-body-class/mixins/body-class", ["exports", "ember-body-class/util/bodyClass"], function (_exports, _bodyClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    router: Ember.inject.service(),
    actions: {
      loading(transition) {
        const document = Ember.getOwner(this).lookup('service:-document');
        const body = document.body;
        (0, _bodyClass.addClass)(body, 'loading');
        transition.finally(function () {
          (0, _bodyClass.removeClass)(body, 'loading');
        });
        return true;
      },
      error: function /* error, transition */
      () {
        const document = Ember.getOwner(this).lookup('service:-document');
        const body = document.body;
        (0, _bodyClass.addClass)(body, 'error');
        this.router.on('routeDidChange', () => {
          if (this.router.currentRouteName !== 'error') {
            (0, _bodyClass.removeClass)(body, 'error');
          }
        });
        return true;
      }
    }
  });
});