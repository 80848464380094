define("ember-svg-jar/inlined/illustration-popup-style-bottom", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M0 38.06h50V0H0zm2.667-2.406h44.962v-9.855H2.667z\" fill=\"#fff\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "height": "39",
      "viewBox": "0 0 50 39",
      "width": "50",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});