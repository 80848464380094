define("ember-svg-jar/inlined/icon-logo-magento", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M12 17.315v28.592L20.001 50l-.056-28.599 20.027-10.228L59.999 21.4v28.586L68 45.907V17.29L40.028 3 12 17.315z\" fill=\"#F26322\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M44.002 50.729l-3.995 2.065-4.016-2.046V22L28 26.106l.014 28.735L40 61l12-6.159V26.106L44.002 22v28.729z\" fill=\"#F26322\"/>",
    "attrs": {
      "width": "80",
      "height": "64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});