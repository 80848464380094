define("ember-svg-jar/inlined/icon-logo-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M24 32.5h5v-4.75h-5v4.75zM34 42V23H19v19h5v-4.75h5V42h5zm9-9.5h5v-4.75h-5v4.75zm0 4.75h10V23H38v19h5v-4.75zM57 42h5V23h-5v19zM34.566 9.023H11V19h4.917v-4.989H36.6l3.899-3.954 3.899 3.954h20.684V19H70V9.023H46.434L40.5 3l-5.934 6.023zM65.083 45v4.989H44.4l-3.9 3.955-3.899-3.955H15.917V45H11v9.978h23.566L40.5 61l5.934-6.022H70V45h-4.917z\" fill=\"#FFCB50\" fill-opacity=\".75\"/>",
    "attrs": {
      "viewBox": "0 0 80 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});