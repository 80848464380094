define("ember-link/helpers/link", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-link/link"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  let LinkHelper = _exports.default = (_dec = Ember.inject.service('link-manager'), (_class = class LinkHelper extends Ember.Helper {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "linkManager", _descriptor, this);
    }
    /**
     * Normalizes the positional and named parameters passed to this helper.
     *
     * @param positional [route?, ...models?, query?]
     * @param named { route?, models?, model?, query? }
     */
    normalizeLinkParams(positional, named) {
      (false && !(!('queryParams' in named)) && Ember.assert(`You provided 'queryParams', but the parameter you mean is just 'query'.`, !('queryParams' in named)));
      (false && !(!('routeName' in named)) && Ember.assert(`You provided 'routeName', but the parameter you mean is just 'route'.`, !('routeName' in named)));
      if (named.fromURL) {
        (false && !(!['route', 'models', 'model', 'query'].some(name => named[name])) && Ember.assert(`When specifying a serialized 'fromURL' ('${named.fromURL}'), you can't provide any further 'LinkParams'.`, !['route', 'models', 'model', 'query'].some(name => named[name])));
        return this.linkManager.getLinkParamsFromURL(named.fromURL);
      }
      (false && !(!(positional[0] && named.route)) && Ember.assert(`Either pass the target route name as a positional parameter ('${positional[0]}') or pass it as a named parameter ('${named.route}').`, !(positional[0] && named.route)));
      (false && !(Boolean(positional[0] || named.route)) && Ember.assert(`Neither specified the target route name as a positional or named parameter ('route').`, Boolean(positional[0] || named.route)));
      const positionalQueryParameters = positional.length > 0 && (0, _link.isQueryParams)(positional[positional.length - 1]) ? positional[positional.length - 1] : undefined;
      (false && !(!positional.slice(0, -1).some(argument => (0, _link.isQueryParams)(argument))) && Ember.assert(`Query parameters either need to be specified as the last positional parameter or as the named 'query' parameter.`, !positional.slice(0, -1).some(argument => (0, _link.isQueryParams)(argument))));
      (false && !(!(named.query && positionalQueryParameters)) && Ember.assert(`Either specify query parameters as the last positional parameter ('${positional[positional.length - 1]}') or as the named 'query' parameter ('${named.query}').`, !(named.query && positionalQueryParameters)));
      (false && !(!(positional.length > 1 && !(0, _link.isQueryParams)(positional[positional.length - 1]) && (named.models || named.model))) && Ember.assert(`Either specify models as positional parameters, as the named 'models' parameter, or as the named 'model' parameter as a short hand for a single model.`, !(positional.length > 1 && !(0, _link.isQueryParams)(positional[positional.length - 1]) && (named.models || named.model))));
      (false && !(!(named.models && named.model)) && Ember.assert(`Either pass multiple 'models' ('${named.models}') or pass a single 'model' ('${named.model}'). `, !(named.models && named.model)));
      return {
        route: named.route ?? positional[0],
        models: Array.isArray(named.models) ? named.models : named.model ? [named.model] : positional.length > 1 ? positional.slice(1, positionalQueryParameters ? -1 : undefined) : undefined,
        query: named.query ?? positionalQueryParameters,
        onTransitionTo: named.onTransitionTo,
        onReplaceWith: named.onReplaceWith
      };
    }

    /**
     * Normalizes and extracts the `UILinkParams` from the named params.
     *
     * @param named { preventDefault? }
     */
    normalizeUIParams(named) {
      return {
        preventDefault: named.preventDefault ?? true
      };
    }
    compute(positional, named) {
      const linkParams = this.normalizeLinkParams(positional, named);
      const uiParams = this.normalizeUIParams(named);
      return this.linkManager.createUILink(linkParams, uiParams);
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "linkManager", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});