define("ember-svg-jar/inlined/icon-focus-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.2\" d=\"M11.998 19.112c3.705 0 7.094-2.664 9.002-7.103-1.908-4.439-5.297-7.103-9.002-7.103-3.701 0-7.09 2.664-8.998 7.103 1.908 4.441 5.297 7.103 9.002 7.103h-.004z\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.2\" d=\"M15.079 12.014a3.077 3.077 0 11-6.154-.002 3.077 3.077 0 016.154.002z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "2.25 4.16 19.5 15.71"
    }
  };
});