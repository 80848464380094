define("ember-phoenix-channel/components/socket-message-log", ["exports", "ember-phoenix-channel/templates/components/socket-message-log"], function (_exports, _socketMessageLog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    layout: _socketMessageLog.default,
    classNames: ['SocketLog'],
    didInsertElement() {
      this._super(...arguments);
      if (this.get('channelService')) {
        this.get('channelService').on('socketMessage', msg => {
          const output = `<strong>${msg.kind}</strong>: ${msg.msg}<br/>${msg.data}`;
          this.push(output);
        });
      } else {
        const msg = "Need to specify a channel service to receive messages, i.e: " + "{{socket-message-log channelService=myChannel}}";
        this.push(msg);
      }
    },
    push(msg) {
      const logItem = `<li>${msg}</li>`;
      document.querySelector('.SocketLogMessages').append(logItem);
    },
    actions: {
      pushMessage(msg) {
        this.push(msg);
      }
    }
  });
});