define("ember-svg-jar/inlined/illustration-pink-sky-background", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M459.129 299.098C482.752 369.416 418.759 75 418.759 75l-27-223.5-385-6.5c-88.208 92.725-150.931 380.385 88.936 341.231 299.833-48.943 333.905 24.971 363.434 112.867z\" fill=\"#ede1ef\"/><g fill=\"#fff\"><g opacity=\".5\"><path d=\"M393.741 116.484H167.75c-8.012 0-14.75-6.556-14.75-14.751v-6.191c0-8.013 6.556-14.75 14.75-14.75h225.991c8.013 0 14.75 6.555 14.75 14.75v6.191a14.689 14.689 0 01-14.75 14.751zM412.134 68.408h-90.506c-7.83 0-14.204-6.374-14.204-14.204 0-7.83 6.374-14.204 14.204-14.204h90.506c7.83 0 14.204 6.374 14.204 14.204 0 7.83-6.374 14.204-14.204 14.204zM429.069 156h-90.505c-7.831 0-14.205-6.374-14.205-14.204 0-7.831 6.374-14.204 14.205-14.204h90.505c7.83 0 14.204 6.373 14.204 14.204 0 7.83-6.374 14.204-14.204 14.204z\"/><path d=\"M381.54 128.684c-4.37 0-8.012-2.731-8.012-6.009s3.642-6.009 8.012-6.009h-34.782c4.371 0 8.013 2.731 8.013 6.009s-3.642 6.009-8.013 6.009zM392.83 81.155c-4.552 0-8.376-2.913-8.376-6.373 0-3.46 3.824-6.374 8.376-6.374h-36.42c4.552 0 8.376 2.914 8.376 6.374 0 3.46-3.824 6.373-8.376 6.373z\"/></g><g opacity=\".5\"><path d=\"M2.34 162.753h186.455c6.831 0 12.575-5.589 12.575-12.575v-4.503c0-6.831-5.589-12.575-12.575-12.575H2.339c-6.83 0-12.575 5.589-12.575 12.575v4.503c.155 6.986 5.744 12.575 12.575 12.575zM-13.228 122.598h74.986c6.52 0 11.798-5.278 11.798-11.799 0-6.52-5.278-11.799-11.798-11.799h-74.986c-6.521 0-11.8 5.279-11.8 11.799 0 6.521 5.434 11.799 11.8 11.799zM-27.201 195.1h74.986c6.52 0 11.799-5.279 11.799-11.799 0-6.521-5.279-11.799-11.799-11.799h-74.986c-6.52 0-11.799 5.278-11.799 11.799 0 6.52 5.279 11.799 11.799 11.799z\"/><path d=\"M12.233 172.433c3.726 0 6.675-2.328 6.675-5.123 0-2.794-2.95-5.123-6.675-5.123h28.876c-3.726 0-6.676 2.329-6.676 5.123 0 2.795 2.95 5.123 6.676 5.123zM2.762 133.155c3.882 0 6.831-2.329 6.831-5.279 0-2.949-3.105-5.278-6.83-5.278H32.88c-3.881 0-6.831 2.329-6.831 5.278 0 2.95 3.105 5.279 6.831 5.279z\"/></g></g>",
    "attrs": {
      "fill": "none",
      "height": "310",
      "viewBox": "0 0 375 310",
      "width": "375",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});