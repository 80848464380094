define('ember-highlight/components/highlight-terms/component', ['exports', 'ember-highlight/components/highlight-terms/template'], function (exports, _template) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  const HighlightTerm = Ember.Component.extend({
    layout: _template.default,
    classNames: ['highlight-terms'],

    didReceiveAttrs() {
      this._super(...arguments);
      var term = this.get('term');
      var options = this.getProperties('caseSensitive', 'wordsOnly');

      Ember.run.scheduleOnce('afterRender', this, function () {
        this.unhighlight();
        this.highlight(term, options);
      });
    },

    willDestroyElement() {
      this._super(...arguments);
      this.unhighlight();
    },

    highlight(term, options) {
      if (term) {
        if (Array.isArray(term)) {
          term = term.reduce((all, item) => {
            if (item !== undefined) {
              if (Array.isArray(item)) {
                all = all.concat(...item);
              } else {
                all.push(item);
              }
            }

            return all;
          }, []);
        }

        if (this.$()) {
          this.$().highlight(term, options);
        }
      }
    },

    unhighlight() {
      let $el = this.$();

      if ($el && typeof $el.unhighlight === 'function') {
        this.$().unhighlight();
      }
    }
  });

  HighlightTerm.reopenClass({
    positionalParams: 'term'
  });

  exports.default = HighlightTerm;
});