define("ember-svg-jar/inlined/icon-notification-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M14.4 21.1c0-.1 0-.1 0 0-.1-.1-.1-.1-.2-.1H9.8c-.1 0-.1 0-.2.1 0 0-.1.1-.1.2v.3c0 .7.3 1.3.7 1.8.5.5 1.1.7 1.8.7s1.3-.3 1.8-.7c.5-.5.7-1.1.7-1.8v-.3c0-.1 0-.1-.1-.2.1 0 .1 0 0 0zm7.1-1.8c-1.1-2.3-1.7-4.8-1.8-7.3v-.8c0-1.5-.3-3-1-4.3S17 4.4 15.8 3.6c-.7-.5-1.5-.8-2.3-.9V1.5c0-.4-.2-.8-.4-1.1S12.4 0 12 0c-.4 0-.8.2-1.1.4s-.4.7-.4 1.1v1.2c-1 .2-2 .6-2.9 1.3-1.1.9-1.9 2-2.5 3.2-.6 1.3-.9 2.6-.8 4v.8c0 2.5-.6 5.1-1.8 7.3 0 .1-.1.2-.1.2 0 .1 0 .2.1.2 0 .1.1.1.2.2s.2.1.3.1h18c.1 0 .2 0 .2-.1.1 0 .1-.1.2-.2 0-.1.1-.2.1-.2v-.2z\" fill=\"#baebe1\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});