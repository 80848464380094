define("ember-svg-jar/inlined/icon-logo-shopify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M37.47 5.004c.322 0 .647.11.958.323-2.395 1.127-4.961 3.965-6.045 9.632l-4.564 1.414c1.27-4.323 4.284-11.369 9.65-11.369zm2.22 2.101c.459 1.152.758 2.804.758 5.034l-.002.324-5.774 1.788c1.111-4.291 3.196-6.364 5.018-7.146zm5.511 3.886l-2.795.865.001-.603c0-1.847-.256-3.335-.668-4.514 1.653.208 2.754 2.088 3.462 4.252zM58.62 14.28a.56.56 0 00-.506-.47c-.21-.018-4.663-.348-4.663-.348s-3.093-3.07-3.432-3.41c-.34-.34-1.003-.236-1.26-.16-.039.01-.676.208-1.731.534-1.034-2.973-2.857-5.704-6.064-5.704a4.9 4.9 0 00-.271.008C39.78 3.524 38.65 3 37.675 3c-7.472 0-11.041 9.34-12.16 14.087-2.904.9-4.966 1.539-5.23 1.622-1.62.508-1.672.56-1.884 2.086C18.24 21.951 14 54.743 14 54.743l33.041 6.19 17.903-3.872S58.66 14.57 58.62 14.28z\" fill=\"#8DB849\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M58.114 13.81c-.21-.018-4.663-.348-4.663-.348s-3.093-3.07-3.432-3.41a.844.844 0 00-.478-.219l-2.498 51.1 17.901-3.872S58.66 14.57 58.62 14.28a.56.56 0 00-.506-.47z\" fill=\"#5A863E\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M40.964 23.702l-2.207 6.566s-1.935-1.032-4.305-1.032c-3.476 0-3.651 2.181-3.651 2.731 0 3 7.817 4.148 7.817 11.173 0 5.527-3.505 9.085-8.231 9.085-5.672 0-8.572-3.53-8.572-3.53l1.518-5.017s2.982 2.56 5.498 2.56c1.643 0 2.312-1.295 2.312-2.24 0-3.912-6.414-4.087-6.414-10.515 0-5.41 3.883-10.646 11.722-10.646 3.02 0 4.513.865 4.513.865z\" fill=\"#FFFFFE\"/>",
    "attrs": {
      "width": "80",
      "height": "64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});