define("ember-power-select/components/power-select/trigger", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component"], function (_exports, _applyDecoratedDescriptor2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @select.selected}}
    {{#if @selectedItemComponent}}
      {{component (ensure-safe-component @selectedItemComponent) extra=(readonly @extra) option=(readonly @select.selected) select=(readonly @select)}}
    {{else}}
      <span class="ember-power-select-selected-item">{{yield @select.selected @select}}</span>
    {{/if}}
    {{#if (and @allowClear (not @select.disabled))}}
      <span class="ember-power-select-clear-btn" role="button" {{on "mousedown" this.clear}} {{on "touchstart" this.clear}}>&times;</span>
    {{/if}}
  {{else}}
    {{component (ensure-safe-component @placeholderComponent) placeholder=@placeholder}}
  {{/if}}
  <span class="ember-power-select-status-icon"></span>
  
  */
  {
    "id": "m7Dyu+SM",
    "block": "{\"symbols\":[\"@placeholder\",\"@placeholderComponent\",\"@select\",\"&default\",\"@extra\",\"@selectedItemComponent\",\"@allowClear\"],\"statements\":[[6,[37,4],[[32,3,[\"selected\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[32,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],[[30,[36,0],[[32,6]],null]],[[\"extra\",\"option\",\"select\"],[[30,[36,3],[[32,5]],null],[30,[36,3],[[32,3,[\"selected\"]]],null],[30,[36,3],[[32,3]],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"ember-power-select-selected-item\"],[12],[18,4,[[32,3,[\"selected\"]],[32,3]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,6],[[32,7],[30,[36,5],[[32,3,[\"disabled\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"span\"],[24,0,\"ember-power-select-clear-btn\"],[24,\"role\",\"button\"],[4,[38,2],[\"mousedown\",[32,0,[\"clear\"]]],null],[4,[38,2],[\"touchstart\",[32,0,[\"clear\"]]],null],[12],[2,\"×\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],[[32,2]],null]],[[\"placeholder\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"span\"],[14,0,\"ember-power-select-status-icon\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"ensure-safe-component\",\"component\",\"on\",\"readonly\",\"if\",\"not\",\"and\"]}",
    "moduleName": "ember-power-select/components/power-select/trigger.hbs"
  });
  let Trigger = _exports.default = (_dec = Ember._action, (_class = class Trigger extends _component.default {
    clear(e) {
      e.stopPropagation();
      this.args.select.actions.select(null);
      if (e.type === 'touchstart') {
        return false;
      }
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "clear", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype)), _class));
  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Trigger);
});