define("ember-svg-jar/inlined/icon-interact-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M11.087 12.306a6.898 6.898 0 015.45-2.65c.936 0 1.835.184 2.666.527.16.066.33-.078.277-.242a8.875 8.875 0 00-1.473-2.756 8.987 8.987 0 00-6.53-3.43c-2.55-.14-5.06.81-6.87 2.62-2.65 2.66-3.37 6.74-1.81 10.13.13.33.58 1.13.93 1.75.09.14.08.31-.02.44-.21.3-.47.68-.68.99-.27.41-.29.93-.06 1.36.23.43.67.7 1.16.7h6.82c.143 0 .286-.004.43-.011.176-.009.253-.229.13-.356a5.258 5.258 0 01-.27-.302c-2.11-2.51-2.17-6.2-.15-8.77z\" clip-rule=\"evenodd\"/><path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M21.053 19.76c.192-.333.437-.77.504-.938a5.078 5.078 0 00-1.008-5.686 5.005 5.005 0 00-3.848-1.467 5.04 5.04 0 00-3.554 8.276 5.03 5.03 0 003.85 1.797h.015c.45-.002 2.448 0 3.629 0 .346 0 .662-.19.826-.494a.946.946 0 00-.047-.97 11.812 11.812 0 00-.367-.517z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "1.98 3.74 20.04 18"
    }
  };
});