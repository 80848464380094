define("ember-svg-jar/inlined/icon-users-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M22.1 18.943a1 1 0 01-1-1c0-.463-.182-2.01-2.53-2.492a1 1 0 11.4-1.959c3.05.624 4.13 2.788 4.13 4.451a1 1 0 01-1 1zM18.35 12.4a1.002 1.002 0 01-.35-1.938 1.773 1.773 0 001.141-1.639c0-.758-.489-1.43-1.217-1.666a1.001 1.001 0 01.614-1.904 3.748 3.748 0 012.603 3.58 3.787 3.787 0 01-2.439 3.501 1.01 1.01 0 01-.35.064zM2.2 18.943a1 1 0 01-1-1c0-1.663 1.081-3.827 4.13-4.45a1 1 0 11.4 1.959c-2.347.48-2.53 2.028-2.53 2.491a1 1 0 01-1 1zM5.95 12.4a.998.998 0 01-.35-.065 3.785 3.785 0 01-2.44-3.494 3.75 3.75 0 012.603-3.587 1 1 0 11.614 1.904A1.754 1.754 0 005.16 8.833a1.77 1.77 0 001.141 1.63 1.002 1.002 0 01-.35 1.936zm6.13 2.116c-4.979 0-7.209 2.754-7.209 5.484a.75.75 0 00.75.75l12.918-.018a.75.75 0 00.75-.75c0-2.721-2.23-5.466-7.209-5.466zm0-1.515a4.881 4.881 0 004.875-4.876A4.88 4.88 0 0012.08 3.25a4.88 4.88 0 00-4.875 4.875 4.881 4.881 0 004.875 4.876z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "1.2 3.25 21.9 17.5"
    }
  };
});