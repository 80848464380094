define("ember-composable-helpers/helpers/find-by", ["exports", "ember-composable-helpers/utils/as-array"], function (_exports, _asArray) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.findBy = findBy;
  function findBy(_ref) {
    let [byPath, value, array] = _ref;
    if (Ember.isEmpty(byPath)) {
      return [];
    }
    return Ember.A((0, _asArray.default)(array)).findBy(byPath, value);
  }
  var _default = _exports.default = Ember.Helper.helper(findBy);
});