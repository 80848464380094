define("ember-svg-jar/inlined/icon-logo-digests", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-rule=\"evenodd\"><path d=\"M53.492 12.502H63V62H18V3h35.492z\" stroke=\"#e9ecef\"/><g fill-rule=\"evenodd\"><path d=\"M52.898 3L63 13.14H52.898z\" fill=\"#e9ecef\"/><path d=\"M39.122 17.75c0 4.586-3.697 8.297-8.265 8.297s-8.265-3.711-8.265-8.297 3.697-8.297 8.265-8.297v8.297z\" fill=\"#02193c\"/><path d=\"M41.878 15.906h-8.266V7.61c4.568 0 8.266 3.712 8.266 8.297z\" fill=\"#02193c\"/><path d=\"M36.367 40.797H57.49V38.03H36.367v2.766zm0 4.609H51.98v-2.765H36.367z\" fill=\"#e9ecef\"/><path d=\"M26.01 45.406l-3.418-3.186 1.253-1.179 2.165 2.024 5.43-5.034 1.254 1.18z\" fill=\"#53cdb5\"/><path d=\"M36.367 52.781H57.49v-2.765H36.367zm0 3.688H51.98v-2.766H36.367z\" fill=\"#e9ecef\"/><path d=\"M26.01 56.469l-3.418-3.186 1.253-1.162 2.165 2.007 5.43-5.034 1.254 1.179z\" fill=\"#53cdb5\"/><path d=\"M43.714 28.813h2.755V23.28h-2.755v5.532zm5.511 0h2.755V16.828h-2.755zm5.51 0h2.755v-8.297h-2.755z\" fill=\"#02193c\"/></g></g>",
    "attrs": {
      "fill": "none",
      "viewBox": "0 0 80 64",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});