define("ember-cli-flash/templates/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "xNmPkBxY",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[11,\"div\"],[16,0,[31,[\"flash-message \",[32,0,[\"alertType\"]],\" \",[30,[36,0],[[32,0,[\"exiting\"]],\"exiting\"],null],\" \",[30,[36,0],[[32,0,[\"active\"]],\"active\"],null]]]],[24,\"role\",\"alert\"],[17,2],[4,[38,2],[\"click\",[32,0,[\"onClick\"]]],null],[4,[38,3],[[32,0,[\"onDidInsert\"]]],null],[4,[38,4],[[32,0,[\"onWillDestroy\"]]],null],[12],[2,\"\\n\"],[6,[37,0],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,1,[[32,0],[32,0,[\"flash\"]],[30,[36,1],[[32,0],\"onClose\"],null]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,0,[\"flash\",\"message\"]]],[2,\"\\n\"],[6,[37,0],[[32,0,[\"showProgressBar\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"alert-progress\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"alert-progressBar\"],[15,5,[32,0,[\"progressDuration\"]]],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"on\",\"did-insert\",\"will-destroy\"]}",
    "moduleName": "ember-cli-flash/templates/components/flash-message.hbs"
  });
});