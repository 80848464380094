define("ember-svg-jar/inlined/icon-dictionary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M20.5 16.375v-12.5C20.5 2.839 19.66 2 18.625 2h-12.5A3.125 3.125 0 003 5.125V19.5A2.5 2.5 0 005.5 22h14.375a.625.625 0 100-1.25h-.625v-2.615a1.873 1.873 0 001.25-1.76zM18 20.75H5.5a1.25 1.25 0 010-2.5H18v2.5zm.625-3.75H5.5c-.457 0-.88.132-1.25.348V5.125c0-1.036.84-1.875 1.875-1.875h12.5c.344 0 .625.28.625.625v12.5a.627.627 0 01-.625.625zM12.309 6.094c-.213-.424-.906-.424-1.118 0l-3.75 7.5a.624.624 0 101.116.56L9.637 12h4.226l1.078 2.155a.622.622 0 00.838.28.625.625 0 00.28-.84l-3.75-7.501zm-2.047 4.656l1.488-2.977 1.488 2.977h-2.976z\" fill=\"#86888A\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});