define("ember-link/components/link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template(
  /*
    {{~yield
    (link
      route=@route
      models=@models
      model=@model
      query=@query
      fromURL=@fromURL
      preventDefault=@preventDefault
      onTransitionTo=@onTransitionTo
      onReplaceWith=@onReplaceWith
    )
  ~}}
  
  */
  {
    "id": "RtbmNOKq",
    "block": "{\"symbols\":[\"@onReplaceWith\",\"@onTransitionTo\",\"@preventDefault\",\"@fromURL\",\"@query\",\"@model\",\"@models\",\"@route\",\"&default\"],\"statements\":[[18,9,[[30,[36,0],null,[[\"route\",\"models\",\"model\",\"query\",\"fromURL\",\"preventDefault\",\"onTransitionTo\",\"onReplaceWith\"],[[32,8],[32,7],[32,6],[32,5],[32,4],[32,3],[32,2],[32,1]]]]]]],\"hasEval\":false,\"upvars\":[\"link\"]}",
    "moduleName": "ember-link/components/link/template.hbs"
  });
});