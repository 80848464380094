define("ember-svg-jar/inlined/illustration-delete-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18.75 6a.25.25 0 01.25.25v.5a.25.25 0 01-.25.25h-.59l-1.038 11.634A1.529 1.529 0 0115.628 20H8.372a1.529 1.529 0 01-1.494-1.366L5.841 7H5.25A.25.25 0 015 6.75v-.5A.25.25 0 015.25 6H8.5l1.05-1.4c.248-.331.786-.6 1.2-.6h2.5c.414 0 .952.269 1.2.6L15.5 6h3.25zm-2.625 12.544L17.156 7H6.844l1.031 11.544c.02.252.243.456.496.456h7.258a.506.506 0 00.496-.456zM10.35 5.2l-.6.8h4.5l-.6-.8a.567.567 0 00-.4-.2h-2.5a.567.567 0 00-.4.2z\" fill=\"#0E1116\" fill-opacity=\".5\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});