define("ember-svg-jar/inlined/illustration-layer-add-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g fill=\"currentColor\" fill-rule=\"evenodd\"><path d=\"M9.707 5.941a.573.573 0 01-.307.761L2.058 9.824l7.647 3.273 8.36-3.554c.3-.127.648.007.778.3a.572.572 0 01-.306.76l-4.231 1.799 4.385 1.841a.57.57 0 01.021 1.047.604.604 0 01-.175.115L9.965 19.05a.615.615 0 01-.419.019l.064.003a.584.584 0 01-.23-.047L.352 15.16a.58.58 0 01-.305-.763.587.587 0 01.589-.35l4.198-1.77L.353 10.36a.585.585 0 01-.352-.493v-.089a.58.58 0 01.355-.491l8.572-3.645c.3-.127.648.007.779.3zm-3.405 6.965L2.139 14.66l7.565 3.239 7.31-3.108-4.19-1.759-2.859 1.216a.597.597 0 01-.424.018l.07.005a.594.594 0 01-.232-.047z\"/><path d=\"M15.592 7.404l.31-.31a.186.186 0 000-.264l-2.424-2.424h5.334A.188.188 0 0019 4.218v-.437a.188.188 0 00-.188-.188h-5.334l2.424-2.423a.188.188 0 000-.265l-.31-.31a.19.19 0 00-.265 0l-3.272 3.273a.188.188 0 000 .265l3.272 3.272a.188.188 0 00.265 0z\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "height": "20",
      "viewBox": "0 0 19 20",
      "width": "19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});