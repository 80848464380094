define("ember-cli-flash/flash/object", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-cli-flash/utils/computed"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor;
  // Note:
  // To avoid https://github.com/poteto/ember-cli-flash/issues/341 from happening, this class can't simply be called Object
  let FlashObject = _exports.default = (_dec = (0, _computed.guidFor)('message').readOnly(), (_class = class FlashObject extends Ember.Object.extend(Ember.Evented) {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "exitTimer", null);
      (0, _defineProperty2.default)(this, "exiting", false);
      (0, _defineProperty2.default)(this, "isExitable", true);
      (0, _defineProperty2.default)(this, "initializedTime", null);
      (0, _initializerDefineProperty2.default)(this, "_guid", _descriptor, this);
    }
    init() {
      super.init(...arguments);
      if (this.sticky) {
        return;
      }
      this.timerTask();
      this._setInitializedTime();
    }
    destroyMessage() {
      this._cancelTimer();
      if (this.exitTaskInstance) {
        Ember.run.cancel(this.exitTaskInstance);
        this._teardown();
      } else {
        this.exitTimerTask();
      }
    }
    exitMessage() {
      if (!this.isExitable) {
        return;
      }
      this.exitTimerTask();
      this.trigger('didExitMessage');
    }
    willDestroy() {
      if (this.onDestroy) {
        this.onDestroy();
      }
      this._cancelTimer();
      this._cancelTimer('exitTaskInstance');
      super.willDestroy(...arguments);
    }
    preventExit() {
      Ember.set(this, 'isExitable', false);
    }
    allowExit() {
      Ember.set(this, 'isExitable', true);
      this._checkIfShouldExit();
    }
    timerTask() {
      if (!this.timeout) {
        return;
      }
      const timerTaskInstance = Ember.run.later(() => {
        this.exitMessage();
      }, this.timeout);
      Ember.set(this, 'timerTaskInstance', timerTaskInstance);
    }
    exitTimerTask() {
      if (this.isDestroyed) {
        return;
      }
      Ember.set(this, 'exiting', true);
      if (this.extendedTimeout) {
        let exitTaskInstance = Ember.run.later(() => {
          this._teardown();
        }, this.extendedTimeout);
        Ember.set(this, 'exitTaskInstance', exitTaskInstance);
      } else {
        this._teardown();
      }
    }
    _setInitializedTime() {
      let currentTime = new Date().getTime();
      Ember.set(this, 'initializedTime', currentTime);
      return this.initializedTime;
    }
    _getElapsedTime() {
      let currentTime = new Date().getTime();
      return currentTime - this.initializedTime;
    }
    _cancelTimer() {
      let taskName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'timerTaskInstance';
      if (this[taskName]) {
        Ember.run.cancel(this[taskName]);
      }
    }
    _checkIfShouldExit() {
      if (this._getElapsedTime() >= this.timeout && !this.sticky) {
        this._cancelTimer();
        this.exitMessage();
      }
    }
    _teardown() {
      const queue = this.flashService?.queue;
      if (queue) {
        queue.removeObject(this);
      }
      this.destroy();
      this.trigger('didDestroyMessage');
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_guid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
});