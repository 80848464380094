define("ember-intl/-private/formatters/-format-datetime", ["exports", "@babel/runtime/helpers/esm/defineProperty", "fast-memoize", "ember-intl/-private/formatters/-base"], function (_exports, _defineProperty2, _fastMemoize, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Copyright 2015, Yahoo! Inc.
   * Copyrights licensed under the New BSD License. See the accompanying LICENSE file for terms.
   */

  const DATE_TIME_OPTIONS = ['localeMatcher', 'formatMatcher', 'timeZone', 'hour12', 'weekday', 'era', 'year', 'month', 'day', 'hour', 'minute', 'second', 'timeZoneName', 'hourCycle'];

  /**
   * @private
   * @hide
   */
  class FormatDateTime extends _base.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "createNativeFormatter", (0, _fastMemoize.default)((locales, options) => {
        return new Intl.DateTimeFormat(locales, options);
      }));
    }
    get options() {
      return DATE_TIME_OPTIONS;
    }
    format(locale, value, formatOptions) {
      const formatterOptions = this.readOptions(formatOptions);
      this.validateFormatterOptions(locale, formatterOptions);
      const formatterInstance = this.createNativeFormatter(locale, formatterOptions);
      return formatterInstance.format(new Date(value));
    }
  }
  _exports.default = FormatDateTime;
  (0, _defineProperty2.default)(FormatDateTime, "type", void 0);
});