define("ember-svg-jar/inlined/icon-chatbot-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M21.75 7.5H15V2.25C15 1.01 13.99 0 12.75 0H2.25C1.01 0 0 1.01 0 2.25v7.5C0 10.99 1.01 12 2.25 12H3v3.75a.75.75 0 001.28.53l3-3L8.56 12H9v5.25c0 1.24 1.01 2.25 2.25 2.25h4.19l4.28 4.28a.749.749 0 001.28-.53V19.5h.75c1.24 0 2.25-1.01 2.25-2.25v-7.5c0-1.24-1.01-2.25-2.25-2.25z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
});