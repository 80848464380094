define("ember-svg-jar/inlined/icon-logo-zapier-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M57.25 50.013a17.342 17.342 0 01-1.114 6.122 17.388 17.388 0 01-6.124 1.116h-.025a17.385 17.385 0 01-6.122-1.115 17.345 17.345 0 01-1.115-6.123v-.026a17.354 17.354 0 011.113-6.121 17.33 17.33 0 016.124-1.116h.025c2.156.001 4.22.397 6.124 1.116a17.334 17.334 0 011.114 6.121zm21.347-4.846H61.67l11.97-11.97a29.117 29.117 0 00-3.134-3.704v-.001a29.227 29.227 0 00-3.703-3.13l-11.97 11.97V21.404A29.137 29.137 0 0050.015 21h-.03c-1.643 0-3.25.14-4.818.404v16.928l-11.97-11.97a28.96 28.96 0 00-3.702 3.132l-.006.004a29.182 29.182 0 00-3.128 3.699l11.97 11.97H21.405S21 48.347 21 49.99v.02c0 1.644.14 3.255.404 4.823h16.928L26.36 66.803a29.206 29.206 0 006.836 6.836l11.97-11.97v16.928c1.566.263 3.173.401 4.812.403h.041a29.26 29.26 0 004.812-.403V61.668L66.803 73.64a29.225 29.225 0 003.702-3.13l.003-.004a29.282 29.282 0 003.13-3.702l-11.97-11.97h16.93c.263-1.565.4-3.172.402-4.812v-.042a29.178 29.178 0 00-.403-4.812z\" fill=\"#fff\"/>",
    "attrs": {
      "viewBox": "0 0 100 100",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});