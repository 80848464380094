define("ember-svg-jar/inlined/icon-users-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M18.775 14.473c2.435.5 3.325 2.118 3.325 3.47M18.234 6.207a2.749 2.749 0 01.117 5.192M5.691 20c0-2.11 1.665-4.734 6.459-4.734 4.793 0 6.458 2.605 6.458 4.715\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M16.276 8.125a4.125 4.125 0 11-8.25 0 4.125 4.125 0 018.25 0z\" clip-rule=\"evenodd\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M5.525 14.473c-2.434.5-3.324 2.118-3.324 3.47M6.066 6.207a2.749 2.749 0 00-.117 5.192\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "1.45 3.25 21.4 17.5"
    }
  };
});