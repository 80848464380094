define("ember-svg-jar/inlined/icon-innovate-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.2\" d=\"M4.5 12h5v8.001h-5a1 1 0 01-1-1v-6A1 1 0 014.5 12zm6-4h5v12h-6V9a1 1 0 011-1z\" clip-rule=\"evenodd\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.2\" d=\"M16.5 4h4a1 1 0 011 1v14.001a1 1 0 01-1 1h-5.001v-15a1 1 0 011-1.001z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "2.75 3.25 19.5 17.5"
    }
  };
});