define("ember-svg-jar/inlined/icon-help-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M8.509 20.588a8.984 8.984 0 0010.156-1.81c3.512-3.524 3.517-9.24 0-12.771a8.986 8.986 0 00-12.728 0c-2.75 2.76-3.345 6.848-1.803 10.19.198.495.352.893.352 1.278 0 1.078-1.037 2.413-.346 3.106.691.693 2.021-.347 3.09-.353.383 0 .787.16 1.279.36zm3.567-4.334l.006.035\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M12.077 14.006c-.011-.82.733-1.168 1.287-1.486.674-.373 1.132-.967 1.132-1.791a2.196 2.196 0 00-2.194-2.202 2.19 2.19 0 00-2.195 2.202\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "2.55 2.62 19.5 19.56"
    }
  };
});