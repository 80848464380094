define("ember-truth-helpers/helpers/is-equal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.isEqual = isEqual;
  function isEqual(_ref) {
    let [a, b] = _ref;
    return Ember.isEqual(a, b);
  }
  var _default = _exports.default = Ember.Helper.helper(isEqual);
});