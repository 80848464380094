define("ember-svg-jar/inlined/icon-delight-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M16.748 16.364c.674-.484.973-1.193 1.204-1.945.156-.507.2-3.393.211-4.49a1.383 1.383 0 012.766-.01c.04 2.111.27 4.593-.398 6.632-.575 1.758-1.736 3.291-3.32 4.257\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M13.595 20.907c-.01-1.213.029-4.113.202-5.057.29-1.588 1.83-3.724 4.303-3.02M7.252 16.364c-.674-.484-.974-1.193-1.205-1.945-.156-.507-.2-3.393-.21-4.49a1.383 1.383 0 00-2.766-.01c-.04 2.111-.27 4.593.397 6.632.576 1.758 1.737 3.291 3.321 4.257\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M10.407 20.907c.01-1.213-.03-4.113-.202-5.057-.29-1.588-1.83-3.724-4.304-3.02\"/><path fill=\"currentColor\" fill-rule=\"evenodd\" stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M12.578 3.237l.647 1.294a.437.437 0 00.33.236l1.448.208a.429.429 0 01.241.734l-1.048 1.007a.423.423 0 00-.125.38l.248 1.421a.435.435 0 01-.634.454l-1.294-.67a.44.44 0 00-.405 0l-1.296.67a.435.435 0 01-.633-.454l.248-1.42a.425.425 0 00-.125-.381L9.13 5.71a.43.43 0 01.242-.735l1.449-.208a.434.434 0 00.329-.236l.649-1.294a.439.439 0 01.78 0z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "2.25 2.25 19.5 19.41"
    }
  };
});