define("ember-svg-jar/inlined/icon-logo-emarsys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M60 12.667L21 30v-8.667L60 4v8.667z\" fill=\"#32D17E\"/><path d=\"M41 30.5V39l19-8.5V22l-19 8.5z\" fill=\"#D9E506\"/><path d=\"M31 52.197V61l29-13.197V39L31 52.197z\" fill=\"#6984D1\"/><path d=\"M41 30.5V39l-20-8.5V22l20 8.5z\" fill=\"#40877E\"/>",
    "attrs": {
      "width": "80",
      "height": "64",
      "viewBox": "0 0 80 64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});