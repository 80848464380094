define("ember-svg-jar/inlined/illustration-2fa-phone-ok", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g filter=\"url(#illustration-2fa-phone-ok_svg__filter0_d)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M81.839 138c-4.882 0-8.839-4.048-8.839-9.042V14.042C73 9.048 76.957 5 81.839 5h43.989C130.893 5 135 9.201 135 14.383v114.234c0 5.182-4.107 9.383-9.172 9.383h-43.99z\" fill=\"#fff\"/></g><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M77 122h55V20H77v102z\" fill=\"#02193C\" fill-opacity=\".1\"/><path d=\"M110 130a5 5 0 11-10.001-.001A5 5 0 01110 130\" fill=\"#0E1116\" fill-opacity=\".2\"/><path d=\"M105 54c-9.374 0-17 7.626-17 17 0 9.374 7.626 17 17 17 9.374 0 17-7.626 17-17 0-9.374-7.626-17-17-17z\" fill=\"#53CDB5\" fill-opacity=\".75\"/><path d=\"M113.576 66.41l-9.404 9.176a1.463 1.463 0 01-1.023.414c-.37 0-.741-.139-1.023-.414l-4.702-4.588a1.386 1.386 0 010-1.996 1.47 1.47 0 012.046 0l3.679 3.59 8.381-8.178a1.47 1.47 0 012.046 0 1.387 1.387 0 010 1.996z\" fill=\"#FAFAFA\"/><defs><filter id=\"illustration-2fa-phone-ok_svg__filter0_d\" x=\"63\" y=\"0\" width=\"82\" height=\"153\" filterUnits=\"userSpaceOnUse\" color-interpolation-filters=\"sRGB\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"/><feOffset dy=\"5\"/><feGaussianBlur stdDeviation=\"5\"/><feColorMatrix values=\"0 0 0 0 0.054902 0 0 0 0 0.0666667 0 0 0 0 0.0862745 0 0 0 0.2 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow\" result=\"shape\"/></filter></defs>",
    "attrs": {
      "width": "206",
      "height": "153",
      "viewBox": "0 0 206 153",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});