define("ember-svg-jar/inlined/icon-innovate-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M7.046 11.99H4.242c-.686 0-1.242.556-1.242 1.242v5.962c0 .684.556 1.242 1.242 1.242h2.804c.686 0 1.242-.558 1.242-1.242v-5.961c0-.687-.556-1.242-1.242-1.242zm6.587-4.222H10.85c-.686 0-1.242.556-1.242 1.242v10.183c0 .686.556 1.242 1.242 1.242h2.783c.686 0 1.242-.556 1.242-1.242V9.01c0-.686-.556-1.242-1.242-1.242zm7.125-4.264h-3.322c-.686 0-1.242.556-1.242 1.242v14.51c0 .686.556 1.242 1.242 1.242h3.322c.686 0 1.242-.556 1.242-1.242V4.746c0-.686-.556-1.242-1.242-1.242z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "3 3.5 19 16.99"
    }
  };
});