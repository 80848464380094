define("ember-svg-jar/inlined/icon-focus-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" d=\"M12 4c2.068 0 4.03.718 5.737 2.046 1.707 1.319 3.16 3.249 4.204 5.663a.728.728 0 010 .572C19.854 17.111 16.137 20 12 20h-.01c-4.127 0-7.844-2.89-9.931-7.719a.728.728 0 010-.572C4.146 6.88 7.863 4 11.99 4zm0 4.121c-2.156 0-3.902 1.736-3.902 3.879 0 2.133 1.746 3.87 3.902 3.87 2.146 0 3.893-1.737 3.893-3.87A3.887 3.887 0 0012 8.121zm.001 1.453a2.43 2.43 0 012.43 2.424A2.428 2.428 0 0112 14.412c-1.346 0-2.439-1.086-2.439-2.414 0-.165.02-.32.049-.475h.049a1.997 1.997 0 002-1.92c.107-.02.224-.03.341-.03z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "2 4 20 16"
    }
  };
});