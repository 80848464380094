define("ember-svg-jar/inlined/icon-interact-light", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M9.316 20.901c-.807-.003-2.613 0-4.734 0a.79.79 0 01-.653-1.23c.197-.294.448-.655.646-.934a.879.879 0 00.044-.946c-.3-.524-.75-1.322-.866-1.61-1.393-3.023-.855-6.716 1.629-9.21a8.12 8.12 0 0112.15.734\"/><path stroke=\"currentColor\" stroke-linecap=\"round\" stroke-linejoin=\"round\" stroke-width=\"1.5\" d=\"M15.855 20.902a5.145 5.145 0 01-3.97-1.847c-1.56-1.85-1.61-4.63-.11-6.53a5.154 5.154 0 017.712-.467 5.198 5.198 0 011.036 5.846c-.074.185-.36.69-.551 1.023a.559.559 0 00.028.6c.126.178.285.406.41.593a.502.502 0 01-.414.782c-1.347 0-3.629-.002-4.14 0z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "2.25 3.83 19.5 17.82"
    }
  };
});