define("ember-svg-jar/inlined/icon-logo-slack", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 46a6 6 0 006-6v-6h-6a6 6 0 100 12zm14.914-11.79c-3.248 0-5.88 2.664-5.88 5.953v14.883c0 3.288 2.632 5.954 5.88 5.954 3.247 0 5.88-2.666 5.88-5.954V40.163c0-3.289-2.633-5.954-5.88-5.954z\" fill=\"#D31A69\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M26 9a6 6 0 006 6h6V9a6 6 0 10-12 0zm11.791 15.053c0-3.248-2.665-5.88-5.953-5.88H16.953c-3.287 0-5.953 2.632-5.953 5.88 0 3.247 2.666 5.88 5.953 5.88h14.885c3.288 0 5.953-2.633 5.953-5.88z\" fill=\"#3FB3E1\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M47.947 29.932c3.248 0 5.88-2.665 5.88-5.954V9.094c0-3.288-2.632-5.953-5.88-5.953-3.247 0-5.88 2.665-5.88 5.953v14.884c0 3.288 2.633 5.954 5.88 5.954zM63 18a6 6 0 00-6 6v6h6a6 6 0 100-12z\" fill=\"#05AF72\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M42.068 40.087c0 3.247 2.666 5.88 5.954 5.88h14.883c3.288 0 5.954-2.633 5.954-5.88 0-3.248-2.665-5.88-5.954-5.88H48.022c-3.288 0-5.954 2.632-5.954 5.88zm11.76 15.032a5.88 5.88 0 00-5.88-5.88h-5.88v5.88a5.88 5.88 0 1011.76 0z\" fill=\"#F1AF21\"/>",
    "attrs": {
      "width": "80",
      "height": "64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});