define("ember-svg-jar/inlined/icon-logo-zapier", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M51.67 27.167h16.927A29.17 29.17 0 0169 31.979v.042a29.17 29.17 0 01-.403 4.812h-16.93l11.971 11.97a29.28 29.28 0 01-3.13 3.703l-.003.002a29.224 29.224 0 01-3.702 3.131l-11.97-11.97v16.928A29.252 29.252 0 0140.02 61h-.041a29.285 29.285 0 01-4.812-.403V43.668L23.197 55.64a29.205 29.205 0 01-6.836-6.836l11.97-11.97H11.405A29.004 29.004 0 0111 32.01v-.02c0-1.644.404-4.823.404-4.823h16.928L16.36 15.197a29.18 29.18 0 013.128-3.699l.006-.004a28.96 28.96 0 013.702-3.132l11.97 11.97V3.404A29.145 29.145 0 0139.984 3h.03c1.643 0 3.252.14 4.818.404v16.928l11.97-11.97a29.117 29.117 0 016.836 6.835L51.67 27.167zm-5.534 10.968a17.342 17.342 0 001.114-6.122v-.026a17.332 17.332 0 00-1.114-6.121 17.325 17.325 0 00-6.124-1.116h-.025c-2.155.001-4.22.397-6.124 1.116a17.355 17.355 0 00-1.113 6.121v.026a17.344 17.344 0 001.115 6.123 17.385 17.385 0 006.122 1.115h.025a17.388 17.388 0 006.124-1.116z\" fill=\"#FF4A00\"/>",
    "attrs": {
      "width": "80",
      "height": "64",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});