define("@glimmer/util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports._WeakSet = _exports.Stack = _exports.SERIALIZATION_FIRST_NODE_STRING = _exports.HAS_NATIVE_SYMBOL = _exports.EMPTY_ARRAY = _exports.DictSet = void 0;
  _exports.assert = debugAssert;
  _exports.assertNever = assertNever;
  _exports.assign = assign;
  _exports.beginTestSteps = void 0;
  _exports.castToBrowser = castToBrowser;
  _exports.castToSimple = castToSimple;
  _exports.checkNode = checkNode;
  _exports.clearElement = clearElement;
  _exports.constants = constants;
  _exports.debugToString = void 0;
  _exports.decodeHandle = decodeHandle;
  _exports.decodeImmediate = decodeImmediate;
  _exports.decodeNegative = decodeNegative;
  _exports.decodePositive = decodePositive;
  _exports.deprecate = deprecate;
  _exports.dict = dict;
  _exports.encodeHandle = encodeHandle;
  _exports.encodeImmediate = encodeImmediate;
  _exports.encodeNegative = encodeNegative;
  _exports.encodePositive = encodePositive;
  _exports.endTestSteps = void 0;
  _exports.ensureGuid = ensureGuid;
  _exports.exhausted = exhausted;
  _exports.expect = expect;
  _exports.extractHandle = extractHandle;
  _exports.fillNulls = fillNulls;
  _exports.initializeGuid = initializeGuid;
  _exports.isDict = isDict;
  _exports.isErrHandle = isErrHandle;
  _exports.isHandle = isHandle;
  _exports.isNonPrimitiveHandle = isNonPrimitiveHandle;
  _exports.isObject = isObject;
  _exports.isOkHandle = isOkHandle;
  _exports.isSerializationFirstNode = isSerializationFirstNode;
  _exports.isSmallInt = isSmallInt;
  _exports.keys = keys;
  _exports.logStep = void 0;
  _exports.strip = strip;
  _exports.tuple = _exports.symbol = void 0;
  _exports.unreachable = unreachable;
  _exports.unwrap = unwrap;
  _exports.unwrapHandle = unwrapHandle;
  _exports.unwrapTemplate = unwrapTemplate;
  _exports.values = values;
  _exports.verifySteps = void 0;
  var EMPTY_ARRAY = _exports.EMPTY_ARRAY = Object.freeze([]); // import Logger from './logger';
  // let alreadyWarned = false;

  function debugAssert(test, msg) {
    // if (!alreadyWarned) {
    //   alreadyWarned = true;
    //   Logger.warn("Don't leave debug assertions on in public builds");
    // }
    if (!test) {
      throw new Error(msg || 'assertion failure');
    }
  }
  function deprecate(desc) {
    console.warn(`DEPRECATION: ${desc}`);
  }
  var GUID = 0;
  function initializeGuid(object) {
    return object._guid = ++GUID;
  }
  function ensureGuid(object) {
    return object._guid || initializeGuid(object);
  }
  function dict() {
    return Object.create(null);
  }
  function isDict(u) {
    return u !== null && u !== undefined;
  }
  function isObject(u) {
    return typeof u === 'object' && u !== null;
  }
  class DictSet {
    constructor() {
      this.dict = dict();
    }
    add(obj) {
      if (typeof obj === 'string') this.dict[obj] = obj;else this.dict[ensureGuid(obj)] = obj;
      return this;
    }
    delete(obj) {
      if (typeof obj === 'string') delete this.dict[obj];else if (obj._guid) delete this.dict[obj._guid];
    }
  }
  _exports.DictSet = DictSet;
  class StackImpl {
    constructor() {
      this.stack = [];
      this.current = null;
    }
    get size() {
      return this.stack.length;
    }
    push(item) {
      this.current = item;
      this.stack.push(item);
    }
    pop() {
      var item = this.stack.pop();
      var len = this.stack.length;
      this.current = len === 0 ? null : this.stack[len - 1];
      return item === undefined ? null : item;
    }
    nth(from) {
      var len = this.stack.length;
      return len < from ? null : this.stack[len - from];
    }
    isEmpty() {
      return this.stack.length === 0;
    }
    toArray() {
      return this.stack;
    }
  }
  _exports.Stack = StackImpl;
  function clearElement(parent) {
    var current = parent.firstChild;
    while (current) {
      var next = current.nextSibling;
      parent.removeChild(current);
      current = next;
    }
  }
  var SERIALIZATION_FIRST_NODE_STRING = _exports.SERIALIZATION_FIRST_NODE_STRING = '%+b:0%';
  function isSerializationFirstNode(node) {
    return node.nodeValue === SERIALIZATION_FIRST_NODE_STRING;
  }
  var {
    keys: objKeys
  } = Object;
  function assign(obj) {
    for (var i = 1; i < arguments.length; i++) {
      var assignment = arguments[i];
      if (assignment === null || typeof assignment !== 'object') continue;
      var _keys = objKeys(assignment);
      for (var j = 0; j < _keys.length; j++) {
        var key = _keys[j];
        obj[key] = assignment[key];
      }
    }
    return obj;
  }
  function fillNulls(count) {
    var arr = new Array(count);
    for (var i = 0; i < count; i++) {
      arr[i] = null;
    }
    return arr;
  }
  function values(obj) {
    var vals = [];
    for (var key in obj) {
      vals.push(obj[key]);
    }
    return vals;
  }
  var HAS_NATIVE_SYMBOL = _exports.HAS_NATIVE_SYMBOL = function () {
    if (typeof Symbol !== 'function') {
      return false;
    } // eslint-disable-next-line symbol-description

    return typeof Symbol() === 'symbol';
  }();
  function keys(obj) {
    return Object.keys(obj);
  }
  function unwrap(val) {
    if (val === null || val === undefined) throw new Error(`Expected value to be present`);
    return val;
  }
  function expect(val, message) {
    if (val === null || val === undefined) throw new Error(message);
    return val;
  }
  function unreachable(message) {
    if (message === void 0) {
      message = 'unreachable';
    }
    return new Error(message);
  }
  function exhausted(value) {
    throw new Error(`Exhausted ${value}`);
  }
  var tuple = function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    return args;
  };
  _exports.tuple = tuple;
  var symbol = _exports.symbol = HAS_NATIVE_SYMBOL ? Symbol : key => `__${key}${Math.floor(Math.random() * Date.now())}__`;
  function strip(strings) {
    var out = '';
    for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
      args[_key2 - 1] = arguments[_key2];
    }
    for (var i = 0; i < strings.length; i++) {
      var string = strings[i];
      var dynamic = args[i] !== undefined ? String(args[i]) : '';
      out += `${string}${dynamic}`;
    }
    var lines = out.split('\n');
    while (lines.length && lines[0].match(/^\s*$/)) {
      lines.shift();
    }
    while (lines.length && lines[lines.length - 1].match(/^\s*$/)) {
      lines.pop();
    }
    var min = Infinity;
    for (var line of lines) {
      var leading = line.match(/^\s*/)[0].length;
      min = Math.min(min, leading);
    }
    var stripped = [];
    for (var _line of lines) {
      stripped.push(_line.slice(min));
    }
    return stripped.join('\n');
  }
  function isHandle(value) {
    return value >= 0;
  }
  function isNonPrimitiveHandle(value) {
    return value > 3
    /* ENCODED_UNDEFINED_HANDLE */;
  }
  function constants() {
    for (var _len3 = arguments.length, values = new Array(_len3), _key3 = 0; _key3 < _len3; _key3++) {
      values[_key3] = arguments[_key3];
    }
    return [false, true, null, undefined, ...values];
  }
  function isSmallInt(value) {
    return value % 1 === 0 && value <= 536870911
    /* MAX_INT */ && value >= -536870912
    /* MIN_INT */;
  }
  function encodeNegative(num) {
    return num & -536870913
    /* SIGN_BIT */;
  }
  function decodeNegative(num) {
    return num | ~-536870913
    /* SIGN_BIT */;
  }
  function encodePositive(num) {
    return ~num;
  }
  function decodePositive(num) {
    return ~num;
  }
  function encodeHandle(num) {
    return num;
  }
  function decodeHandle(num) {
    return num;
  }
  function encodeImmediate(num) {
    num |= 0;
    return num < 0 ? encodeNegative(num) : encodePositive(num);
  }
  function decodeImmediate(num) {
    num |= 0;
    return num > -536870913
    /* SIGN_BIT */ ? decodePositive(num) : decodeNegative(num);
  } // Warm

  [1, -1].forEach(x => decodeImmediate(encodeImmediate(x)));
  function unwrapHandle(handle) {
    if (typeof handle === 'number') {
      return handle;
    } else {
      var error = handle.errors[0];
      throw new Error(`Compile Error: ${error.problem} @ ${error.span.start}..${error.span.end}`);
    }
  }
  function unwrapTemplate(template) {
    if (template.result === 'error') {
      throw new Error(`Compile Error: ${template.problem} @ ${template.span.start}..${template.span.end}`);
    }
    return template;
  }
  function extractHandle(handle) {
    if (typeof handle === 'number') {
      return handle;
    } else {
      return handle.handle;
    }
  }
  function isOkHandle(handle) {
    return typeof handle === 'number';
  }
  function isErrHandle(handle) {
    return typeof handle === 'number';
  }
  var weakSet = _exports._WeakSet = typeof WeakSet === 'function' ? WeakSet : class WeakSetPolyFill {
    constructor() {
      this._map = new WeakMap();
    }
    add(val) {
      this._map.set(val, true);
      return this;
    }
    delete(val) {
      return this._map.delete(val);
    }
    has(val) {
      return this._map.has(val);
    }
  };
  function castToSimple(node) {
    if (isDocument(node)) {
      return node;
    } else if (isElement(node)) {
      return node;
    } else {
      return node;
    }
  }
  function castToBrowser(node, sugaryCheck) {
    if (node === null || node === undefined) {
      return null;
    }
    if (typeof document === undefined) {
      throw new Error('Attempted to cast to a browser node in a non-browser context');
    }
    if (isDocument(node)) {
      return node;
    }
    if (node.ownerDocument !== document) {
      throw new Error('Attempted to cast to a browser node with a node that was not created from this document');
    }
    return checkNode(node, sugaryCheck);
  }
  function checkError(from, check) {
    return new Error(`cannot cast a ${from} into ${check}`);
  }
  function isDocument(node) {
    return node.nodeType === 9
    /* DOCUMENT_NODE */;
  }
  function isElement(node) {
    return node.nodeType === 1
    /* ELEMENT_NODE */;
  }
  function checkNode(node, check) {
    var isMatch = false;
    if (node !== null) {
      if (typeof check === 'string') {
        isMatch = stringCheckNode(node, check);
      } else if (Array.isArray(check)) {
        isMatch = check.some(c => stringCheckNode(node, c));
      } else {
        throw unreachable();
      }
    }
    if (isMatch) {
      return node;
    } else {
      throw checkError(`SimpleElement(${node})`, check);
    }
  }
  function stringCheckNode(node, check) {
    switch (check) {
      case 'NODE':
        return true;
      case 'HTML':
        return node instanceof HTMLElement;
      case 'SVG':
        return node instanceof SVGElement;
      case 'ELEMENT':
        return node instanceof Element;
      default:
        if (check.toUpperCase() === check) {
          throw new Error(`BUG: this code is missing handling for a generic node type`);
        }
        return node instanceof Element && node.tagName.toLowerCase() === check;
    }
  }
  var debugToString;
  if (false /* DEBUG */) {
    var getFunctionName = fn => {
      var functionName = fn.name;
      if (functionName === undefined) {
        var match = Function.prototype.toString.call(fn).match(/function (\w+)\s*\(/);
        functionName = match && match[1] || '';
      }
      return functionName.replace(/^bound /, '');
    };
    var getObjectName = obj => {
      var name;
      var className;
      if (obj.constructor && obj.constructor !== Object) {
        className = getFunctionName(obj.constructor);
      }
      if ('toString' in obj && obj.toString !== Object.prototype.toString && obj.toString !== Function.prototype.toString) {
        name = obj.toString();
      } // If the class has a decent looking name, and the `toString` is one of the
      // default Ember toStrings, replace the constructor portion of the toString
      // with the class name. We check the length of the class name to prevent doing
      // this when the value is minified.

      if (name && name.match(/<.*:ember\d+>/) && className && className[0] !== '_' && className.length > 2 && className !== 'Class') {
        return name.replace(/<.*:/, `<${className}:`);
      }
      return name || className;
    };
    var getPrimitiveName = value => {
      return String(value);
    };
    debugToString = value => {
      if (typeof value === 'function') {
        return getFunctionName(value) || `(unknown function)`;
      } else if (typeof value === 'object' && value !== null) {
        return getObjectName(value) || `(unknown object)`;
      } else {
        return getPrimitiveName(value);
      }
    };
  }
  var debugToString$1 = _exports.debugToString = debugToString;
  var beginTestSteps;
  var endTestSteps;
  var verifySteps;
  var logStep;
  function assertNever(value, desc) {
    if (desc === void 0) {
      desc = 'unexpected unreachable branch';
    }
    console.log('unreachable', value);
    console.trace(`${desc} :: ${JSON.stringify(value)} (${value})`);
  }
});
