define("ember-svg-jar/inlined/illustration-mobile-channel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<defs><filter id=\"illustration-mobile-channel_svg__a\" width=\"171.9%\" height=\"133.3%\" x=\"-35.9%\" y=\"-16.7%\" filterUnits=\"objectBoundingBox\"><feOffset dy=\"4\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\" stdDeviation=\"4\"/><feColorMatrix in=\"shadowBlurOuter1\" result=\"shadowMatrixOuter1\" values=\"0 0 0 0 0.0549019608 0 0 0 0 0.0666666667 0 0 0 0 0.0862745098 0 0 0 0.15 0\"/><feMerge><feMergeNode in=\"shadowMatrixOuter1\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></defs><g fill=\"none\" fill-rule=\"evenodd\" filter=\"url(#illustration-mobile-channel_svg__a)\" transform=\"translate(8 4)\"><path fill=\"#FFF\" fill-rule=\"nonzero\" d=\"M9.124 138C4.085 138 0 133.8 0 128.618V9.382C0 4.201 4.085 0 9.124 0h45.408C59.76 0 64 4.36 64 9.736v118.528c0 5.377-4.24 9.736-9.468 9.736H9.124z\"/><path fill=\"#0E1116\" fill-opacity=\".1\" d=\"M3.68 119.6h57.04V14.72H3.68z\"/><path fill=\"#0E1116\" fill-opacity=\".2\" fill-rule=\"nonzero\" d=\"M36.8 128.8a4.6 4.6 0 11-9.2 0 4.6 4.6 0 019.2 0\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "154"
    }
  };
});