define("ember-phoenix-channel/templates/components/socket-message-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "0ckcreQC",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Socket Message Log\\n\"],[10,\"ul\"],[14,0,\"SocketLogMessages\"],[12],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "ember-phoenix-channel/templates/components/socket-message-log.hbs"
  });
});