define("ember-named-blocks-polyfill/helpers/-has-block-params", ["exports", "ember-named-blocks-polyfill"], function (_exports, _emberNamedBlocksPolyfill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Helper.helper(
  /**
   * @param {unknown[]} params
   * @param {object} hash
   * @returns {boolean}
   */
  function _hasBlockParams(params, hash) {
    let [blocksInfo, block, fallback] = params;
    (false && !(params.length === 3 && (blocksInfo === undefined || (0, _emberNamedBlocksPolyfill.isBlocksInfo)(blocksInfo)) && typeof block === 'string' && typeof fallback === 'boolean') && Ember.assert('-has-block-params takes exactly three arguments: the blocks ' + 'info hash, the name of the block and the fallback value', params.length === 3 && (blocksInfo === undefined || (0, _emberNamedBlocksPolyfill.isBlocksInfo)(blocksInfo)) && typeof block === 'string' && typeof fallback === 'boolean'));
    (false && !(Object.keys(hash).length === 0) && Ember.assert('-is-named-block-invocation does not take named arguments', Object.keys(hash).length === 0));
    return blocksInfo ? block in blocksInfo && blocksInfo[block] > 0 : fallback;
  });
});