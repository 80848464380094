define("ember-basic-dropdown/components/basic-dropdown-trigger", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@glimmer/component", "ember-basic-dropdown/utils/has-moved"], function (_exports, _defineProperty2, _applyDecoratedDescriptor2, _component, _hasMoved) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class;
  let BasicDropdownTrigger = _exports.default = (_dec = Ember._action, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, _dec6 = Ember._action, _dec7 = Ember._action, _dec8 = Ember._action, (_class = class BasicDropdownTrigger extends _component.default {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "toggleIsBeingHandledByTouchEvents", false);
      (0, _defineProperty2.default)(this, "touchMoveEvent", void 0);
    }
    // Actions
    /**
     * Allows similair behaviour to `ember-composable-helpers`' `optional` helper.
     * Avoids adding extra dependencies.
     * Can be removed when the template `V1` compatability event handlers are removed.
     *
     * @see https://github.com/cibernox/ember-basic-dropdown/issues/498
     * @memberof BasicDropdownContent
     */
    noop() {}
    handleMouseDown(e) {
      if (this.args.dropdown.disabled) {
        return;
      }
      if (this.args.eventType !== 'mousedown' || e.button !== 0) return;
      if (this.args.stopPropagation) {
        e.stopPropagation();
      }
      this._stopTextSelectionUntilMouseup();
      if (this.toggleIsBeingHandledByTouchEvents) {
        // Some devises have both touchscreen & mouse, and they are not mutually exclusive
        // In those cases the touchdown handler is fired first, and it sets a flag to
        // short-circuit the mouseup so the component is not opened and immediately closed.
        this.toggleIsBeingHandledByTouchEvents = false;
        return;
      }
      this.args.dropdown.actions.toggle(e);
    }
    handleClick(e) {
      if (typeof document === 'undefined') return;
      if (this.isDestroyed || !this.args.dropdown || this.args.dropdown.disabled) {
        return;
      }
      if (this.args.eventType !== undefined && this.args.eventType !== 'click' || e.button !== 0) return;
      if (this.args.stopPropagation) {
        e.stopPropagation();
      }
      if (this.toggleIsBeingHandledByTouchEvents) {
        // Some devises have both touchscreen & mouse, and they are not mutually exclusive
        // In those cases the touchdown handler is fired first, and it sets a flag to
        // short-circuit the mouseup so the component is not opened and immediately closed.
        this.toggleIsBeingHandledByTouchEvents = false;
        return;
      }
      this.args.dropdown.actions.toggle(e);
    }
    handleKeyDown(e) {
      if (this.args.dropdown.disabled) {
        return;
      }
      if (e.keyCode === 13) {
        // Enter
        this.args.dropdown.actions.toggle(e);
      } else if (e.keyCode === 32) {
        // Space
        e.preventDefault(); // prevents the space to trigger a scroll page-next
        this.args.dropdown.actions.toggle(e);
      } else if (e.keyCode === 27) {
        this.args.dropdown.actions.close(e);
      }
    }
    handleTouchStart() {
      document.addEventListener('touchmove', this._touchMoveHandler);
    }
    handleTouchEnd(e) {
      this.toggleIsBeingHandledByTouchEvents = true;
      if (e && e.defaultPrevented || this.args.dropdown.disabled) {
        return;
      }
      if (!(0, _hasMoved.default)(e, this.touchMoveEvent)) {
        this.args.dropdown.actions.toggle(e);
      }
      this.touchMoveEvent = undefined;
      document.removeEventListener('touchmove', this._touchMoveHandler);
      // This next three lines are stolen from hammertime. This prevents the default
      // behaviour of the touchend, but synthetically trigger a focus and a (delayed) click
      // to simulate natural behaviour.
      let target = e.target;
      if (target !== null) {
        target.focus();
      }
      setTimeout(function () {
        if (!e.target) {
          return;
        }
        try {
          let event = document.createEvent('MouseEvents');
          event.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
          e.target.dispatchEvent(event);
        } catch (e) {
          event = new Event('click');
          e.target.dispatchEvent(event);
        }
      }, 0);
      e.preventDefault();
    }
    removeGlobalHandlers() {
      if (typeof document === 'undefined') return;
      document.removeEventListener('touchmove', this._touchMoveHandler);
      document.removeEventListener('mouseup', this._mouseupHandler, true);
    }
    _mouseupHandler() {
      document.removeEventListener('mouseup', this._mouseupHandler, true);
      document.body.classList.remove('ember-basic-dropdown-text-select-disabled');
    }
    _touchMoveHandler(e) {
      this.touchMoveEvent = e;
      document.removeEventListener('touchmove', this._touchMoveHandler);
    }

    // Methods
    _stopTextSelectionUntilMouseup() {
      document.addEventListener('mouseup', this._mouseupHandler, true);
      document.body.classList.add('ember-basic-dropdown-text-select-disabled');
    }
  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleMouseDown", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "handleMouseDown"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleClick", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "handleClick"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleKeyDown", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleKeyDown"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleTouchStart", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchStart"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleTouchEnd", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleTouchEnd"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "removeGlobalHandlers", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "removeGlobalHandlers"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_mouseupHandler", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "_mouseupHandler"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_touchMoveHandler", [_dec8], Object.getOwnPropertyDescriptor(_class.prototype, "_touchMoveHandler"), _class.prototype)), _class));
});