define("ember-svg-jar/inlined/icon-settings-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M13.566 21.5h-1.168a2.293 2.293 0 01-2.293-2.29 1.205 1.205 0 00-.169-.601 1.28 1.28 0 00-.787-.61 1.26 1.26 0 00-.965.131 2.3 2.3 0 01-3.135-.887l-.575-.991c-.01-.017-.022-.034-.03-.052a2.239 2.239 0 01-.209-1.705 2.263 2.263 0 011.066-1.381 1.293 1.293 0 000-2.236 2.292 2.292 0 01-.839-3.124l.631-1.039a2.292 2.292 0 013.12-.83c.188.112.398.171.61.173h.004c.7 0 1.277-.571 1.287-1.273a2.284 2.284 0 012.28-2.285h1.172c1.335.043 2.33 1.103 2.291 2.365a1.278 1.278 0 001.927 1.043 2.298 2.298 0 013.131.837l.582 1.011c.012.02.021.04.03.061a2.295 2.295 0 01-.867 3.06 1.29 1.29 0 00-.604 1.459 1.269 1.269 0 00.623.787c.526.307.899.8 1.053 1.387.154.589.07 1.202-.236 1.727l-.62 1.035a2.292 2.292 0 01-3.12.831 1.347 1.347 0 00-.62-.171 1.276 1.276 0 00-1.278 1.286 2.298 2.298 0 01-2.292 2.282zm-.582-11.969a2.468 2.468 0 110 4.936 2.468 2.468 0 010-4.936z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "4.15 2.5 17.65 19"
    }
  };
});