define("ember-svg-jar/inlined/icon-help-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"currentColor\" fill-rule=\"evenodd\" d=\"M13.729 13.18l-.13.072c-.571.322-.774.483-.77.747a.752.752 0 01-.741.76h-.009a.75.75 0 01-.75-.74c-.016-1.202.955-1.748 1.535-2.074l.129-.073c.51-.283.755-.654.755-1.14a1.45 1.45 0 00-1.446-1.452c-.796 0-1.443.651-1.443 1.452a.75.75 0 01-1.5 0 2.95 2.95 0 012.943-2.952 2.952 2.952 0 012.946 2.952c0 1.036-.54 1.905-1.519 2.447zm-1.522 3.852a.751.751 0 01-.864-.617.767.767 0 01.615-.88.734.734 0 01.86.598l.006.035a.751.751 0 01-.617.864zm6.814-11.375a9.423 9.423 0 00-6.719-2.79 9.422 9.422 0 00-6.718 2.79c-2.805 2.816-3.57 7.137-1.913 10.73.197.491.317.81.317 1.091 0 .333-.142.75-.279 1.151-.264.78-.565 1.662.079 2.308.647.65 1.531.343 2.309.075.398-.138.809-.279 1.135-.28.29 0 .656.146 1.079.317a9.516 9.516 0 0010.71-1.916c3.701-3.715 3.701-9.76 0-13.476z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "none",
      "viewBox": "2.8 2.87 18.99 19.05"
    }
  };
});