define("ember-svg-jar/inlined/illustration-website-survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<filter id=\"illustration-website-survey_svg__a\" color-interpolation-filters=\"sRGB\" filterUnits=\"userSpaceOnUse\" height=\"158\" width=\"244\" x=\"0\" y=\"0\"><feFlood flood-opacity=\"0\" result=\"BackgroundImageFix\"/><feColorMatrix in=\"SourceAlpha\" values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0\"/><feOffset dy=\"5\"/><feGaussianBlur stdDeviation=\"5\"/><feColorMatrix values=\"0 0 0 0 0.054902 0 0 0 0 0.0666667 0 0 0 0 0.0862745 0 0 0 0.1 0\"/><feBlend in2=\"BackgroundImageFix\" result=\"effect1_dropShadow\"/><feBlend in=\"SourceGraphic\" in2=\"effect1_dropShadow\" result=\"shape\"/></filter><mask id=\"illustration-website-survey_svg__b\" height=\"138\" maskUnits=\"userSpaceOnUse\" width=\"224\" x=\"10\" y=\"5\"><path clip-rule=\"evenodd\" d=\"M10 8.2A3.2 3.2 0 0113.2 5h217.6a3.2 3.2 0 013.2 3.2v131.6a3.2 3.2 0 01-3.2 3.2H13.2a3.2 3.2 0 01-3.2-3.2z\" fill=\"#fff\" fill-rule=\"evenodd\"/></mask><g filter=\"url(#illustration-website-survey_svg__a)\"><path clip-rule=\"evenodd\" d=\"M10 8.2A3.2 3.2 0 0113.2 5h217.6a3.2 3.2 0 013.2 3.2v131.6a3.2 3.2 0 01-3.2 3.2H13.2a3.2 3.2 0 01-3.2-3.2z\" fill=\"#fff\" fill-rule=\"evenodd\"/></g><g mask=\"url(#illustration-website-survey_svg__b)\"><g clip-rule=\"evenodd\" fill-rule=\"evenodd\"><path d=\"M10 5h224v12H10z\" fill=\"#0e1116\" fill-opacity=\".1\"/><path d=\"M16 13a2 2 0 100-4 2 2 0 000 4z\" fill=\"#ff5a5a\"/><path d=\"M24 13a2 2 0 100-4 2 2 0 000 4z\" fill=\"#ffc744\"/><path d=\"M32 13a2 2 0 100-4 2 2 0 000 4z\" fill=\"#a3d55f\"/></g><rect fill=\"#0e1116\" fill-opacity=\".2\" height=\"8\" rx=\"1\" width=\"108\" x=\"50\" y=\"49\"/><rect fill=\"#53cdb5\" fill-opacity=\".5\" height=\"8\" rx=\"1\" width=\"24\" x=\"50\" y=\"103\"/><path clip-rule=\"evenodd\" d=\"M50 69a1 1 0 011-1h74a1 1 0 011 1v2a1 1 0 01-1 1H51a1 1 0 01-1-1zm0 11a1 1 0 011-1h78a1 1 0 011 1v2a1 1 0 01-1 1H51a1 1 0 01-1-1z\" fill=\"#0e1116\" fill-opacity=\".2\" fill-rule=\"evenodd\"/></g>",
    "attrs": {
      "fill": "none",
      "height": "158",
      "width": "244",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});