define("ember-link/services/link-manager", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "ember-link/link"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _link) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;
  let LinkManagerService = _exports.default = (_dec = Ember._tracked, _dec2 = Ember.inject.service('router'), _dec3 = Ember._action, _dec4 = Ember._action, (_class = class LinkManagerService extends Ember.Service {
    /**
     * The `RouterService` instance to be used by the generated `Link` instances.
     */

    /**
     * Whether the router has been initialized.
     * This will be `false` in render tests.
     *
     * @see https://github.com/buschtoens/ember-link/issues/126
     */
    get isRouterInitialized() {
      // Ideally we would use the public `router` service here, but accessing
      // the `currentURL` on that service automatically starts the routing layer
      // as a side-effect, which is not our intention here. Once or if Ember.js
      // provides a flag on the `router` service to figure out if routing was
      // already initialized we should switch back to the public service instead.
      //
      // Inspiration for this workaround was taken from the `currentURL()` test
      // helper (see https://github.com/emberjs/ember-test-helpers/blob/v2.1.4/addon-test-support/@ember/test-helpers/setup-application-context.ts#L180)

      // eslint-disable-next-line ember/no-private-routing-service
      return Boolean(Ember.getOwner(this).lookup('router:main').currentURL);
    }

    /**
     * The currently active `Transition` objects.
     */
    get currentTransitionStack() {
      return this._currentTransitionStack;
    }

    /**
     * Creates a `Link` instance.
     */
    createLink(linkParams) {
      return new _link.default(this, linkParams);
    }

    /**
     * Creates a `UILink` instance.
     */
    createUILink(linkParams, uiParams) {
      return new _link.UILink(this, {
        ...linkParams,
        ...uiParams
      });
    }

    /**
     * Deserializes the `LinkParams` to be passed to `createLink` / `createUILink`
     * from a URL.
     *
     * If the URL cannot be recognized by the router, an error is thrown.
     */
    getLinkParamsFromURL(url) {
      const routeInfo = this.router.recognize(url);
      return LinkManagerService.getLinkParamsFromRouteInfo(routeInfo);
    }

    /**
     * Converts a `RouteInfo` object into `LinkParams`.
     */
    static getLinkParamsFromRouteInfo(routeInfo) {
      const models = routeInfo.paramNames.map(name => routeInfo.params[name]);
      return {
        route: routeInfo.name,
        query: routeInfo.queryParams,
        models
      };
    }

    // eslint-disable-next-line @typescript-eslint/ban-types
    constructor(properties) {
      super(properties);

      // Ignore `Argument of type '"routeWillChange"' is not assignable to parameter of type ...`

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      (0, _initializerDefineProperty2.default)(this, "_currentTransitionStack", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "router", _descriptor2, this);
      Ember.addListener(this.router, 'routeWillChange', this.handleRouteWillChange);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Ember.addListener(this.router, 'routeDidChange', this.handleRouteDidChange);
    }
    willDestroy() {
      super.willDestroy();

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Ember.removeListener(this.router, 'routeWillChange', this.handleRouteWillChange);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      Ember.removeListener(this.router, 'routeDidChange', this.handleRouteDidChange);
    }
    handleRouteWillChange(transition) {
      this._currentTransitionStack = [...(this._currentTransitionStack || []), transition];
    }
    handleRouteDidChange() {
      this._currentTransitionStack = undefined;
    }
  }, (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "_currentTransitionStack", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleRouteWillChange", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "handleRouteWillChange"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "handleRouteDidChange", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "handleRouteDidChange"), _class.prototype)), _class));
});